import { useParams } from "react-router-dom";
import React, { useMemo, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { useAuth } from "../../hooks/useAuth";
import { priceCell, TypeCell } from "../../components/Cells";
import { Form, OverlayTrigger, Popover, Tab, Tabs } from "react-bootstrap";
import { Stat, Stats } from "../../components/Page";
import { Table } from "../../components/Table";
import { CopyButton, CopyText } from "../../components/Copy";
import { LocalBuy } from "./LocalBuy";
import { PasteForm } from "./Paste";
import { Loading } from "../../components/Loading";
import { PIViewer } from "../pi/PIViewer";

export function TableViewer({ data }) {
  const [showSingleUnitPrices, setShowSingleUnitPrices] = useState(false);
  const [key, setKey] = useState("original");
  const { user } = useAuth();

  const columns = useMemo(
    () => [
      {
        header: "Name",
        accessorKey: "name",
        cell: (cell) => {
          const value = cell.getValue();
          const original = cell.row.original;
          return <TypeCell id={original.type_id} name={value} />;
        },
      },
      {
        header: "Quantity",
        accessorKey: "quantity",
        meta: {
          align: "end",
        },
        cell: (cell) => {
          const value = cell.getValue();
          return (
            <span className={"text-nowrap"}>{value.toLocaleString()}</span>
          );
        },
      },
      {
        header: "Volume",
        accessorKey: "volume",
        sortingFn: (rowA, rowB, columnId) => {
          if (showSingleUnitPrices) {
            return rowA.original[columnId] - rowB.original[columnId];
          }
          return (
            rowA.original[columnId] * rowA.original.quantity -
            rowB.original[columnId] * rowB.original.quantity
          );
        },
        meta: {
          align: "end",
        },
        cell: (cell) => {
          const value = cell.getValue();
          const quantity = cell.row.original.quantity;
          return (
            <span className={"text-nowrap"}>
              {(value * quantity).toLocaleString()} m<sup>3</sup>
            </span>
          );
        },
        footer: () => {
          const total = data.result.totals.volume;
          return (
            <span className={"text-nowrap"}>
              {total.toLocaleString()} m<sup>3</sup>
            </span>
          );
        },
      },
      {
        header: "Buy (ISK)",
        accessorKey: "buy",
        sortingFn: (rowA, rowB, columnId) => {
          if (showSingleUnitPrices) {
            return rowA.original[columnId] - rowB.original[columnId];
          }
          return (
            rowA.original[columnId] * rowA.original.quantity -
            rowB.original[columnId] * rowB.original.quantity
          );
        },
        meta: {
          align: "end",
        },
        cell: (cell) => {
          const value = cell.getValue();
          if (showSingleUnitPrices) {
            return priceCell(value, false);
          }
          return priceCell(value * cell.row.original.quantity, false);
        },
        footer: () => {
          return priceCell(data.result.totals.buy, false);
        },
      },
      {
        header: "Sell (ISK)",
        accessorKey: "sell",
        sortingFn: (rowA, rowB, columnId) => {
          if (showSingleUnitPrices) {
            return rowA.original[columnId] - rowB.original[columnId];
          }
          return (
            rowA.original[columnId] * rowA.original.quantity -
            rowB.original[columnId] * rowB.original.quantity
          );
        },
        meta: {
          align: "end",
        },
        cell: (cell) => {
          const value = cell.getValue();
          if (showSingleUnitPrices) {
            return priceCell(value, false);
          }
          return priceCell(value * cell.row.original.quantity, false);
        },
        footer: () => {
          return priceCell(data.result.totals.sell, false);
        },
      },
    ],
    [data, showSingleUnitPrices],
  );
  const buy = data.result.totals.buy;
  const sell = data.result.totals.sell;
  const split = (buy + sell) / 2;
  
  return (
    <>
      <div className={"d-flex mb-2"}>
        <div className={"flex-grow-1"}>
          Appraised using{" "}
          <span className={"text-info"}>{data.region.name}</span> prices
          <div className={"text-white-50"}>
            Snapshot taken {new Date(data.created).toLocaleString()}
          </div>
        </div>
        <div>
          <OverlayTrigger
            placement={"bottom"}
            overlay={
              <Popover>
                <Popover.Body>
                  Copies the contents of the paste in a format that can be
                  imported into the EVE Multi-buy tool.
                </Popover.Body>
              </Popover>
            }
          >
            <div className={"d-inline-block"}>
              <CopyButton
                className={"btn btn-outline-info btn-sm mt-2 ms-2"}
                onDoCopy={() => {
                  return data.result.entries
                    .map((row) => {
                      return `${row.quantity} ${row.name}`;
                    })
                    .join("\n");
                }}
              >
                Copy Multibuy
              </CopyButton>
            </div>
          </OverlayTrigger>
        </div>
      </div>
      <Stats>
        <Stat title={"Region"} value={<CopyText text={data.region.name}>{data.region.name}</CopyText>} />
        <Stat
          title={"Buy"}
          value={<CopyText text={buy}>{priceCell(buy)}</CopyText>}
        />
        <Stat
          title={"Sell"}
          value={<CopyText text={sell}>{priceCell(sell)}</CopyText>}
        />
        <Stat
          title={"Split"}
          value={<CopyText text={split}>{priceCell(split)}</CopyText>}
        />
      </Stats>
      <div className={"flex-grow-1 d-flex flex-column overflow-y-auto"}>
        <Form.Check
          type={"switch"}
          id={"single-unit"}
          label={"Show single unit prices"}
          checked={showSingleUnitPrices}
          className={"mb-3"}
          onChange={(e) => setShowSingleUnitPrices(e.target.checked)}
        />
        <Table columns={columns} rows={data.result.entries} showFooter={true} />
      </div>
      <div className={"mt-3"}>
        <Tabs activeKey={key} onSelect={setKey}>
          {data.result.bad_lines.length > 0 && (
            <Tab title={"Errors"} eventKey={"errors"}>
              <p>Some lines did not parse correctly.</p>
              <div className={"list-group"}>
                {data.result.bad_lines.map((row, index) => {
                  return (
                    <div
                      key={index}
                      className={"list-group-item list-group-item-danger"}
                    >
                      {row}
                    </div>
                  );
                })}
              </div>
            </Tab>
          )}
          <Tab title={"Fork"} eventKey={"original"}>
            {user ? (
              <div className={"mt-3"}>
                <PasteForm defaultContent={data.content} />
              </div>
            ) : (
              <div className={"mt-3"}>
                <p>You must be logged in to fork this paste.</p>
              </div>
            )}
          </Tab>
          <Tab title={"Import Optimizer"} eventKey={"import"}>
            {user ? (
              <div className={"mt-3"}>
                {key === "import" && (
                  <LocalBuy
                    foreignRegion={data.region.id}
                    items={data.result.entries}
                  />
                )}
              </div>
            ) : (
              <div className={"mt-3"}>
                <p>You must be logged in to use the import optimizer tool.</p>
              </div>
            )}
          </Tab>
        </Tabs>
      </div>
    </>
  );
}

export function PasteViewer() {
  const { id } = useParams();

  const { data, isLoading } = useQuery({
    queryKey: ["paste", id],
    queryFn: async () => {
      const response = await fetch(`/api/paste/${id}/?expand=region`);
      return response.json();
    },
  });

  if (isLoading) {
    return <Loading />;
  }

  if (data.result.type === 5) {
    return (
      <PIViewer colonyData={JSON.parse(data.content)} />
    )
  } else {
    return (
      <TableViewer data={data}/>
    );
  }
}
