import {Header} from "../../components/Page";
import {useQuery} from "@tanstack/react-query";
import {Loading} from "../../components/Loading";
import {Table} from "../../components/Table";

const UnitFormatter = ({ unitId, value, defaultValue = null, highIsGood = null }) => {
  // Helper to format numbers consistently
  const formatNumber = (num) => {
    return Number(num).toLocaleString(undefined, {
      maximumFractionDigits: 2,
      minimumFractionDigits: 0
    });
  };

  // Helper for time formatting
  const formatTime = (seconds) => {
    const hrs = Math.floor(seconds / 3600);
    const mins = Math.floor((seconds % 3600) / 60);
    const secs = Math.floor(seconds % 60);

    if (hrs > 0) {
      return `${hrs}h ${mins}m ${secs}s`;
    } else if (mins > 0) {
      return `${mins}m ${secs}s`;
    }
    return `${secs}s`;
  };

  const formatValue = () => {
    switch (unitId) {
      // Time units
      case 3:   // Time
      case 123: // trueTime
        return formatTime(value);
      case 101: // Milliseconds
        return `${formatNumber(value)}ms`;
      case 129: // Hours
        return `${formatNumber(value)}h`;
      case 143: // Datetime
        return new Date(value).toISOString();

      // Percentage units
      case 105: // Percentage
      case 108: // Inverse Absolute Percent
      case 109: // Modifier Percent
      case 111: // Inversed Modifier Percent
      case 121: // realPercent
      case 124: // Modifier Relative Percent
      case 127: // Absolute Percent
        return `${formatNumber(value)}%`;

      // Currency
      case 133: // Money
        return `${formatNumber(value)} ISK`;

      // Boolean
      case 137:
        return value ? 'Yes' : 'No';

      // ID fields - left as stubs
      case 115: // groupID
      case 116: // typeID
      case 119: // attributeID
        return `ID:${value}`;

      // Units with specific measurements
      case 102: // Millimeters
        return `${formatNumber(value)}mm`;
      case 103: // MegaPascals
        return `${formatNumber(value)}MPa`;
      case 106: // Teraflops
        return `${formatNumber(value)}TFLOPS`;
      case 107: // MegaWatts
        return `${formatNumber(value)}MW`;
      case 113: // Hitpoints
        return `${formatNumber(value)}HP`;
      case 114: // capacitor units
        return `${formatNumber(value)}GJ`;
      case 118: // Ore units
        return `${formatNumber(value)}m³`;
      case 125: // Newton
        return `${formatNumber(value)}N`;
      case 126: // Light Year
        return `${formatNumber(value)}ly`;
      case 135: // Astronomical Unit
        return `${formatNumber(value)}AU`;
      case 128: // Drone bandwidth
        return `${formatNumber(value)}Mbit/s`;

      // Simple integer units
      case 117: // Sizeclass
      case 120: // attributePoints
      case 122: // Fitting slots
      case 134: // Logistical Capacity
      case 136: // Slot
      case 138: // Units
      case 139: // Bonus
      case 140: // Level
      case 141: // Hardpoints
      case 142: // Sex
        return Math.round(value).toString();

      // Default case - just return formatted number
      default:
        return value;
    }
  };

  if (highIsGood && defaultValue < value) {
    return <span className={"text-success"}>{formatValue()}</span>;
  } else if (highIsGood === false && defaultValue > value) {
    return <span className={"text-success"}>{formatValue()}</span>;
  } else {
    return formatValue();
  }
};

export function DogmaDetails ({ dogma }) {
  return (
    <Table
      rows={dogma}
      columns={[
        {
          header: "Attribute",
          accessorKey: "attribute.name",
          cell: (cell) => {
            const original = cell.row.original;
            return (
              <div>
                <span className={original.attribute.published ? "" : "fa-strikethrough"}>{original.attribute.display_name || original.attribute.name}</span>
                <br />
                <small className={"text-white-50"}>{original.attribute.description}</small>
              </div>
            );
          }
        },
        {
          header: "Value",
          accessorKey: "value",
          cell: (cell) => {
            const original = cell.row.original;
            return <UnitFormatter unitId={original.attribute.unit_id} value={original.value} defaultValue={original.attribute.default_value} highIsGood={original.attribute.high_is_good}/>;
          },
          meta: {
            align: "center"
          }
        },
        {
          header: "Default",
          accessorKey: "attribute.default_value",
          cell: (cell) => {
            const original = cell.row.original;
            return <UnitFormatter unitId={original.attribute.unit_id} value={original.attribute.default_value} />;
          },
          meta: {
            align: "center"
          }
        },
      ]}
    />
  )
}

export function MarketDetailsTab ({ marketId }) {
  const { data: marketItem, isLoading: typeIsLoading } = useQuery({
    queryKey: ["market", "details", marketId],
    queryFn: () =>
      fetch(`/api/types/${marketId}/?expand=dogma_attributes`).then((res) => res.json()),
  });

  if (typeIsLoading) {
    return <Loading />;
  }

  return (
    <div className={"mt-3"}>
      <h1 className={"fs-3"}>Dogma</h1>
      <p>
        Dogma attributes are how EVE Online internally defines the
        properties of items in the game world. This information often
        isn't useful on its own, but is here for reference.
      </p>
      <DogmaDetails dogma={marketItem.dogma_attributes} />
    </div>
  );
}