import { useQuery } from "@tanstack/react-query";
import { Header } from "../../components/Page";
import { ScopeBar } from "../../components/ScopeBar";
import { useStorage } from "../../hooks/useStorage";
import React from "react";
import { Table } from "../../components/Table";
import {
  CharacterAvatar,
  CompactStationLocation, IconCell,
  priceCell,
  TypeCell, TypeLink,
} from "../../components/Cells";
import { FloatingPopup } from "../../components/FloatingPopup";

export function Orders() {
  const [sellSorting, setSellSorting] = useStorage("orderbook.sell.sorting", [
    {
      id: "price",
      desc: false,
    },
  ]);
  const [buySorting, setBuySorting] = useStorage("orderbook.buy.sorting", [
    {
      id: "price",
      desc: false,
    },
  ]);

  const { data: orders, isLoading } = useQuery({
    queryKey: ["orderbook", "orders"],
    queryFn: () => {
      const params = new URLSearchParams();
      params.set(
        "expand",
        ["character", "character.skills", "character.skills.skill"].join(","),
      );
      return fetch(`/api/me/orders/?${params.toString()}`).then((res) =>
        res.json(),
      );
    },
    initialData: [],
  });

  const columns = [
    {
      id: "Character",
      header: "",
      accessorKey: "character",
      cell: (cell) => {
        const { character } = cell.row.original;
        return <CharacterAvatar id={character.id} name={character.name} />;
      }
    },
    {
      id: "type_id",
      header: "",
      accessorKey: "type",
      cell: (cell) => {
        const {type} = cell.row.original;
        return <IconCell id={type.id} name={type.name} iconSize={32}/>;
      }
    },
    {
      header: "Item",
      accessorKey: "type",
      meta: {
        cellClasses: "w-100",
      },
      cell: (cell) => {
        const type = cell.getValue();
        return <TypeLink id={type.id} name={type.name} />;
      },
    },
    {
      header: "Qty",
      accessorKey: "volume_remain",
      meta: {
        align: "center",
      },
    },
    {
      header: "Price (ISK)",
      accessorKey: "price",
      enableSorting: true,
      sortingFn: "alphanumeric",
      meta: {
        align: "center",
      },
      cell: (cell) => {
        const { price, volume_remain, volume_total, is_best_price } = cell.row.original;

        return (
          <FloatingPopup
            trigger={
              <span className={!is_best_price ? "text-danger" : "text-success"}>
                {priceCell(price, false)}
              </span>
            }
            content={
              <dl className={"mb-0 p-2"}>
                <dt>Price Per Unit</dt>
                <dd>{priceCell(price)}</dd>
                <dt>Remaining</dt>
                <dd>
                  {volume_remain} units ({(volume_remain / volume_total) * 100}
                  %)
                </dd>
                <dt>Total</dt>
                <dd>{volume_total} units</dd>
                <dt>Is Best Price</dt>
                <dd>
                  <span className={is_best_price ? "text-success" : "text-danger"}>
                    {is_best_price ? "Yes" : "No"}
                  </span>
                </dd>
              </dl>
            }
          />
        );
      },
    },
    {
      header: "Location",
      accessorKey: "location",
      meta: {
        nowrap: true,
      },
      cell: (cell) => <CompactStationLocation location={cell.getValue()} />,
    },
    {
      header: "Expires",
      accessorKey: "expires",
      meta: {
        align: "center",
      },
      cell: (cell) => {
        const expires = new Date(cell.getValue());
        const now = new Date();

        if (expires < now) {
          return <span className={"text-warning"}>Expired</span>;
        }

        const diff = expires - now;
        const hours = Math.floor(diff / 1000 / 60 / 60);
        const minutes = Math.floor((diff / 1000 / 60) % 60);

        return `${hours}h${minutes}m`;
      },
    },
  ];

  return (
    <>
      <Header title="Orders" />
      <div className={"mb-4"}>
        <ScopeBar scopes={["esi-markets.read_character_orders.v1"]} />
      </div>
      <div className={"mb-4"}>
        <h3 className={"mb-4"}>Sell Orders</h3>
        <Table
          rows={orders.filter((order) => order.is_buy_order === false)}
          sorting={sellSorting}
          setSorting={setSellSorting}
          defaultColumn={{
            enableSorting: false,
          }}
          columns={columns}
        />
      </div>
      <div className={"mb-4"}>
        <h3 className={"mb-4"}>Buy Orders</h3>
        <Table
          rows={orders.filter((order) => order.is_buy_order)}
          sorting={buySorting}
          setSorting={setBuySorting}
          defaultColumn={{
            enableSorting: false,
          }}
          columns={columns}
        />
      </div>
    </>
  );
}
