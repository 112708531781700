import { useQuery } from "@tanstack/react-query";
import { useCallback, useState } from "react";
import { Table } from "../../components/Table";
import { TypeCell, TypeLink } from "../../components/Cells";
import { Block, BlockFooter, BlockHeader } from "../../components/Block";
import { compactNumber } from "../../formatting";
import { Link, useNavigate } from "react-router-dom";
import { savePaste } from "../../mutations/paste";
import { Loading } from "../../components/Loading";
import { SkillRequirements } from "../../components/SkillRequirements";
import {CopyText} from "../../components/Copy";

export function BlueprintInputs({ inputs }) {
  const columns = [
    {
      id: "item",
      header: "Item",
      accessorKey: "item.name",
      cell: (cell) => {
        const original = cell.row.original;
        return <TypeCell id={original.item.id} name={original.item.name} />;
      },
    },
    {
      header: "Quantity",
      accessorKey: "quantity",
      meta: {
        align: "end",
      },
    },
    {
      id: "volume",
      header: "Volume (m³)",
      accessorFn: (row) => row.item.volume * row.quantity,
      meta: {
        align: "end",
      },
      cell: (cell) => {
        const original = cell.row.original;
        return compactNumber(original.item.volume * original.quantity);
      },
      footer: () => {
        const total = inputs.reduce((acc, input) => {
          return acc + input.item.volume * input.quantity;
        }, 0);

        return (
          <CopyText text={total}>
            {compactNumber(total)}m<sup>3</sup>
          </CopyText>
        )
      }
    },
  ];

  return <Table columns={columns} rows={inputs} showFooter={true} />;
}

export function ManufactureTab({ activity, blueprint }) {
  const [quantityMultiplier, setQuantityMultiplier] = useState(1);

  const navigate = useNavigate();
  const saveMutation = savePaste({
    onSuccess: (data) => {
      navigate(`/paste/${data.slug}`);
    },
  });

  const createAppraisal = useCallback(
    (e) => {
      e.target.disabled = true;

      saveMutation.mutate({
        content: activity.inputs
          .map((input) => {
            return `${input.quantity * quantityMultiplier} ${input.item.name}`;
          })
          .join("\n"),
        region: 10000002,
      });
    },
    [saveMutation, quantityMultiplier, activity],
  );

  if (!activity) {
    return (
      <div className={"alert alert-info"}>
        Not available for this blueprint.
      </div>
    );
  }

  return (
    <>
      <Block className={"mb-3"}>
        <BlockHeader className={"d-flex"}>
          <div className={"flex-grow-1"}>
            <Link to={`/market/${blueprint.item.id}`}>
              {blueprint.item.name}
            </Link>
          </div>
          <div>
            {activity.outputs.map((output) => {
              return (
                <div className={"fs-6"} key={output.item.id}>
                  Produces{" "}
                  <span className={"text-info"}>{output.quantity}</span>x{" "}
                  <TypeLink id={output.item.id} name={output.item.name} />
                </div>
              );
            })}
          </div>
        </BlockHeader>
        <BlueprintInputs inputs={activity.inputs} />
        <BlockFooter>
          <div className={"d-flex text-white-50"}>
            <div className={"flex-grow-1"}>
              Takes <span className={"text-info"}>{activity.time}</span> seconds
              to manufacture
            </div>
          </div>
        </BlockFooter>
      </Block>
      <div className={"row"}>
        <div className={"col-2 dark"}>
          <input
            type={"number"}
            className={"form-control"}
            value={quantityMultiplier}
            min={1}
            max={100000}
            onChange={(e) => setQuantityMultiplier(e.target.value)}
          />
        </div>
        <div className={"col-10"}>
          <button
            className={"btn btn-outline-info w-100 mb-3"}
            onClick={createAppraisal}
          >
            Create Appraisal from Blueprint
          </button>
        </div>
      </div>
      <h3>Skill Requirements</h3>
      <p>
        To perform this action, you need the following skills at the specified level.
      </p>
      <SkillRequirements requiredSkills={activity.skills} />
    </>
  );
}

/**
 * Display a blueprint and its inputs.
 *
 * @param item_id
 * @returns {JSX.Element}
 * @constructor
 */
export function Blueprint({ item_id }) {
  const [tool, setTool] = useState("manufacture");

  const { data, isLoading, isError } = useQuery({
    queryKey: ["blueprint", item_id],
    queryFn: () => {
      const url = new URL(
        `/api/industry/blueprints/output/${item_id}/`,
        window.location.origin,
      );
      url.searchParams.set(
        "expand",
        [
          "item",
          "activities",
          "activities.skills.skill",
          "activities.outputs.item",
          "activities.inputs.item"
        ].join(","),
      );

      return fetch(url).then((res) => {
        if (res.status !== 200) {
          throw new Error(res.statusText);
        }
        return res.json();
      });
    },
    retry: false,
  });

  if (isLoading) {
    return <Loading />;
  }

  if (isError) {
    return (
      <div className={"alert alert-info mt-3"}>No industrial data found.</div>
    );
  }

  return (
    <div className={"mt-3"}>
      <div className={"btn-group"}>
        <input
          type={"radio"}
          className={"btn-check"}
          name={"tool"}
          autoComplete={"off"}
          id={"tool-1"}
          checked={tool === "manufacture"}
          onChange={() => setTool("manufacture")}
        />
        <label className={"btn btn-outline-info"} htmlFor={"tool-1"}>
          Manufacture
        </label>
        <input
          type={"radio"}
          className={"btn-check"}
          name={"tool"}
          autoComplete={"off"}
          id={"tool-2"}
          checked={tool === "invention"}
          onChange={() => setTool("invention")}
        />
        <label className={"btn btn-outline-info"} htmlFor={"tool-2"}>
          Invention
        </label>
      </div>
      <div className={"mt-3"}>
        {tool === "manufacture" && (
          <ManufactureTab
            activity={data.activities.find((a) => a.activity_type === 1)}
            blueprint={data}
          />
        )}
        {tool === "invention" && (
          <ManufactureTab
            activity={data.activities.find((a) => a.activity_type === 8)}
            blueprint={data}
          />
        )}
      </div>
    </div>
  );
}
