import { Content, Header, Page } from "../../components/Page";
import { AutocompleteInput } from "../components/AutocompleteInput";
import { useStorage } from "../../hooks/useStorage";
import { compactNumber } from "../../formatting";
import { TypeCell } from "../../components/Cells";
import { DataTable, useDataTable } from "../../components/DataTable";

/**
 * Compares the OrderHistory for the last 30 days and 7 days
 * to the currently available sell orders and identifies the
 * items that could use more supply.
 */
export function StationDemand() {
  const [location, setLocation] = useStorage("market.demand.station", null);
  
  const [state, actions] = useDataTable({
    endpoint: location ? `/api/market/${location.id}/demand/` : null,
    manualFetch: true,
    defaultFilters: [],
  });

  const columns = [
    {
      accessorKey: "item_name",
      header: "Name",
      meta: { align: "start" },
      cell: (cell) => {
        const { item_id, item_name } = cell.row.original;
        return <TypeCell id={item_id} name={item_name} />;
      },
    },
    {
      header: "Price (ISK)",
      columns: [
        {
          accessorKey: "min_price",
          header: "Min",
          cell: (cell) => compactNumber(cell.getValue()),
        },
        {
          accessorKey: "max_price",
          header: "Max",
          cell: (cell) => compactNumber(cell.getValue()),
        },
        {
          accessorKey: "avg_price",
          header: "Avg",
          cell: (cell) => compactNumber(cell.getValue()),
        },
      ]
    },
    {
      header: "Volume",
      columns: [
        {
          accessorKey: "avg_volume_7d",
          header: (
            <abbr title={"Average daily demand for the last 7 days"}>
              7d
            </abbr>
          ),
          cell: (cell) => compactNumber(cell.getValue()),
        },
        {
          accessorKey: "avg_volume_30d",
          header: (
            <abbr title={"Average daily demand for the last 30 days"}>
              30d
            </abbr>
          ),
          cell: (cell) => compactNumber(cell.getValue()),
        },
        {
          id: "perc",
          accessorFn: (row) => {
            const avg = row.avg_volume_30d;
            const available = row.available_volume;

            if (avg < 1) {
              return 0;
            }

            return (available / avg) * 100;
          },
          header: (
            <abbr
              title={
                "Percentage of available items compared to the average 30d demand"
              }
            >
              %
            </abbr>
          ),
          cell: (cell) => {
            return `${cell.getValue().toFixed(0)}%`;
          },
        }
      ]
    },
  ];

  return (
    <Page>
      <Content>
        <Header
          title={"Station Demand"}
          description={
            <>
              Compares the available sell orders in the given station to the
              historical demand for the last 30 days and 7 days. This can help
              you identify items that are in high demand but have low supply.
            </>
          }
        />
        <div className="mb-3 dark">
          <div>
            <AutocompleteInput
              defaultValue={location}
              onChange={setLocation}
              label={"Station"}
              type={"station"}
              helpText={"Select a station to view the demand for items."}
              inputProps={{
                placeholder: "Search for a station...",
                autoFocus: true,
              }}
            />
          </div>
          <button
            onClick={actions.fetchData}
            className={"btn btn-primary w-100 mt-2"}
            disabled={state.isLoading}
          >
            {state.isLoading ? "Calculating..." : "Calculate"}
          </button>

        </div>

        {location && (
          <div className="mt-3">
            <DataTable
              state={state}
              actions={actions}
              columns={columns}
              defaultColumn={{
                meta: {
                  align: "end",
                },
              }}
              filtering={{
                enabled: true,
                allowedFilters: {
                  group: {
                    label: "Item Group",
                    operators: ["&&", "!&"],
                    component: AutocompleteInput,
                    props: {
                      type: "group",
                    },
                  },
                  volume30d: {
                    label: "Volume (30d)",
                    operators: [">=", "<="],
                  },
                  volume7d: {
                    label: "Volume (7d)",
                    operators: [">=", "<="],
                  },
                  min_price: {
                    label: "Min Price",
                    operators: [">=", "<="],
                  },
                  max_price: {
                    label: "Max Price",
                    operators: [">=", "<="],
                  },
                  avg_price: {
                    label: "Avg Price",
                    operators: [">=", "<="],
                  },
                }
              }}
            />
          </div>
        )}
      </Content>
    </Page>
  );
}