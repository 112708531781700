import {Content, Header, Page} from "../../components/Page";
import {AutocompleteInput} from "../components/AutocompleteInput";
import {useStorage} from "../../hooks/useStorage";
import {useQuery} from "@tanstack/react-query";
import {Table} from "../../components/Table";
import {IconCell, TypeLink} from "../../components/Cells";
import React from "react";
import {compactNumber} from "../../formatting";

function Delta ({ value }) {
  return (
    <span className={value > 0 ? "text-success" : value < 0 ? "text-danger" : ""}>
      {value > 0 ? "+" : ""}{compactNumber(value)}
    </span>
  );
}

export function Segment () {
  const [group, setGroup] = useStorage("market.segment.group", null);
  const [region, setRegion] = useStorage("market.segment.region", null);

  const { data, isLoading, refetch} = useQuery({
    queryKey: ["segment", region, group],
    queryFn: () => {
      if (!region || !group) {
        return Promise.resolve(null);
      }

      return fetch(`/api/market/segment/${region.id}/${group.id}/`).then(res => res.json());
    },
    enabled: false
  });

  return (
    <Page>
      <Content>
        <Header
          title={"Segment"}
          description={
            <>
              Analyze a specific market segment to see high-level trends.
            </>
          }
        />
        <div className={"dark mb-3"}>
          <div className={"mb-3"}>
            <AutocompleteInput
              type={"regions"}
              onChange={setRegion}
              defaultValue={region}
              placeholder={"Start typing a region..."}
              helpText={"Select the region to analyse."}
            />
          </div>
          <div className={"mb-3"}>
            <AutocompleteInput
              type={"group"}
              onChange={setGroup}
              defaultValue={group}
              placeholder={"Start typing a market group..."}
              helpText={"Select the market group to analyse."}
            />
          </div>
          <button
            onClick={() => refetch()}
            className={"btn btn-primary w-100"}
            disabled={!region || !group}
          >
            Analyze
          </button>
        </div>
        <Table
          rows={data || []}
          isLoading={isLoading}
          columns={[
            {
              id: "icon",
              header: "",
              accessorKey: "id",
              cell: (cell) => {
                const { id, name } = cell.row.original;
                return <IconCell id={id} name={name} />;
              }
            },
            {
              header: "Name",
              accessorKey: "name",
              meta: {
                cellClasses: "w-100"
              },
              cell: (cell) => {
                const { id, name } = cell.row.original;
                return <TypeLink id={id} name={name} />;
              }
            },
            {
              header: "Price (ISK)",
              columns: [
                {
                  header: "Buy",
                  accessorKey: "highest_buy",
                  meta: {
                    align: "end"
                  },
                  cell: (cell) => compactNumber(cell.getValue())
                },
                {
                  header: "Sell",
                  accessorKey: "lowest_sell",
                  meta: {
                    align: "end"
                  },
                  cell: (cell) => compactNumber(cell.getValue())
                }
              ]
            },
            {
              header: "Orders",
              meta: {
                align: "end"
              },
              columns: [
                {
                  header: "Buy",
                  accessorKey: "buyer_count",
                  meta: {
                    align: "end"
                  },
                  cell: (cell) => compactNumber(cell.getValue())
                },
                {
                  header: "Sell",
                  accessorKey: "seller_count",
                  meta: {
                    align: "end"
                  },
                  cell: (cell) => compactNumber(cell.getValue())
                }
              ]
            },
            {
              id: "price_change",
              header: (
                <abbr title={"Delta in the average price over the specified period."}>
                  Price Change
                </abbr>
              ),
              meta: {
                align: "end"
              },
              columns: [
                {
                  header: "1d",
                  accessorKey: "price_change_1d",
                  meta: {
                    align: "end"
                  },
                  cell: (cell) => <Delta value={cell.getValue()} />
                },
                {
                  header: "7d",
                  accessorKey: "price_change_1w",
                  meta: {
                    align: "end"
                  },
                  cell: (cell) => <Delta value={cell.getValue()} />
                },
                {
                  header: "30d",
                  accessorKey: "price_change_1m",
                  meta: {
                    align: "end"
                  },
                  cell: (cell) => <Delta value={cell.getValue()} />
                },
                {
                  header: "90d",
                  accessorKey: "price_change_3m",
                  meta: {
                    align: "end"
                  },
                  cell: (cell) => <Delta value={cell.getValue()} />
                }
              ]
            },
            {
              id: "volume_change",
              header: (
                <abbr title={"Delta in the average volume over the specified period."}>
                  Volume Change
                </abbr>
              ),
              meta: {
                align: "end"
              },
              columns: [
                {
                  header: "1d",
                  accessorKey: "volume_change_1d",
                  meta: {
                    align: "end"
                  },
                  cell: (cell) => <Delta value={cell.getValue()} />
                },
                {
                  header: "7d",
                  accessorKey: "volume_change_1w",
                  meta: {
                    align: "end"
                  },
                  cell: (cell) => <Delta value={cell.getValue()} />
                },
                {
                  header: "30d",
                  accessorKey: "volume_change_1m",
                  meta: {
                    align: "end"
                  },
                  cell: (cell) => <Delta value={cell.getValue()} />
                },
                {
                  header: "90d",
                  accessorKey: "volume_change_3m",
                  meta: {
                    align: "end"
                  },
                  cell: (cell) => <Delta value={cell.getValue()} />
                }
              ]
            }
          ]}
        />
      </Content>
    </Page>
  );
}