import { hasPermission, useAuth } from "../hooks/useAuth";
import { Navigate, NavLink, Outlet } from "react-router-dom";
import Market from "jsx:../icons/market.svg";
import Logout from "jsx:../icons/logout.svg";
import Characters from "jsx:../icons/characters.svg";
import Seeding from "jsx:../icons/seeding.svg";
import Wallet from "jsx:../icons/wallet.svg";
import Planet from "jsx:../icons/planet.svg";
import Assets from "jsx:../icons/assets.svg";
import Universe from "jsx:../icons/universe.svg";
import Paste from "jsx:../icons/paste.svg";
import Contract from "jsx:../icons/contract.svg";
import Loyalty from "jsx:../icons/loyalty.svg";
import Fitting from "jsx:../icons/fitting.svg";
import DashboardIcon from "jsx:../icons/dashboard.svg";
import Pro from "jsx:../icons/pro.svg";
import Industry from "jsx:../icons/industry.svg";
import Economy from "jsx:../icons/economy.svg";
import { Dashboard } from "../pages/schedule/Dashboard";
import { FloatingPopup } from "../components/FloatingPopup";
import {Loading} from "../components/Loading";
import React from "react";

function SidebarLink({ to, icon, title, description, permission }) {
  if (permission && !hasPermission(permission)) {
    return null;
  }

  const triggerContent = (
    <NavLink
      to={to}
      className={({ isActive, isPending }) =>
        `nav-link py-2 rounded-0 ${isActive ? "active" : ""} ${
          isPending ? "pending" : ""
        }`
      }
    >
      {icon}
    </NavLink>
  );

  const popupContent = <>{description}</>;

  return <FloatingPopup trigger={triggerContent} content={popupContent} />;
}

export function Layout({ unauthenticated, children }) {
  const { user, isLoading } = useAuth();

  if (isLoading) {
    return (
      <main className={"h-100 d-flex"}>
        <div className={"m-auto"}>
          <Loading>
            <h1 className={"h3 mb-3 fw-normal"}>Cosmic Ledger</h1>
          </Loading>
        </div>
      </main>
    );
  }

  if (user === null) {
    return unauthenticated ? unauthenticated : <Navigate to={"/login"} />;
  }

  return (
    <>
      <div id={"main-sidebar"} className={"sidebar vh-100 d-flex flex-column"}>
        <ul
          className={
            "nav nav-pills nav-flush flex-column text-center flex-grow-1"
          }
        >
          <li className={"nav-item"}>
            <SidebarLink
              to={"/"}
              icon={<DashboardIcon className={"icon"}/>}
              title={"Dashboard"}
              description={
                <>
                  The <span className={"text-info"}>dashboard</span> tool shows
                  you upcoming skills, industry jobs, PI extractors, refueling
                  schedules, and more.
                </>
              }
            />
          </li>
          <li className={"nav-item"}>
            <SidebarLink
              to={"/characters"}
              icon={<Characters className={"icon"}/>}
              title={"Characters"}
              description={
                <>
                  The <span className={"text-info"}>character dashboard</span>{" "}
                  shows you a summary of your characters.
                </>
              }
            />
          </li>
          <li className={"nav-item"}>
            <SidebarLink
              to={"/market"}
              icon={<Market className={"icon"}/>}
              title={"Market Browser"}
              description={
                <>
                  The <span className={"text-info"}>market browser</span> shows
                  you all available items, buy and sell orders, and handy
                  information about items.
                </>
              }
            />
          </li>
          <li className={"nav-item"}>
            <SidebarLink
              to={"/economy"}
              icon={<Economy className={"icon"}/>}
              title={"Economy"}
              description={
                <>
                  The <span className={"text-info"}>economy</span> tool shows you
                  the latest market trends and economic indicators.
                </>
              }
            />
          </li>
          <li className={"nav-item"}>
            <SidebarLink
              to={"/wallet"}
              icon={<Wallet className={"icon"}/>}
              title={"Wallet"}
              description={
                <>
                  The <span className={"text-info"}>wallet</span> shows you
                  everything going in and out of your wallets.
                </>
              }
            />
          </li>
          <li className={"nav-item"}>
            <SidebarLink
              to={"/seeding"}
              icon={<Seeding className={"icon"}/>}
              title={"Market Seeding"}
              description={
                <>
                  The <span className={"text-info"}>market seeding</span> tool
                  helps you to seed the market with items.
                </>
              }
            />
          </li>
          <li className={"nav-item"}>
            <SidebarLink
              to={"/assets"}
              icon={<Assets className={"icon"}/>}
              title={"Assets"}
              description={
                <>
                  The <span className={"text-info"}>assets browser</span> tool
                  helps you to manage your assets, including inventories and
                  ships.
                </>
              }
            />
          </li>
          <li className={"nav-item"}>
            <SidebarLink
              to={"/universe"}
              icon={<Universe className={"icon"}/>}
              title={"Universe"}
              description={
                <>
                  The <span className={"text-info"}>universe browser</span> tool
                  helps you to explore the EVE universe.
                </>
              }
            />
          </li>
          <li className={"nav-item"}>
            <SidebarLink
              to={"/pi"}
              icon={<Planet className={"icon"}/>}
              title={"Planetary Interaction"}
              description={
                <>
                  The <span className={"text-info"}>planetary interaction</span>{" "}
                  tool helps you to manage your planetary colonies.
                </>
              }
            />
          </li>
          <li className={"nav-item"}>
            <SidebarLink
              to={"/contracts"}
              icon={<Contract className={"icon"}/>}
              title={"Contracts"}
              description={
                <>
                  The <span className={"text-info"}>contracts browser</span>{" "}
                  shows you all of the the available contracts in the universe.
                </>
              }
            />
          </li>
          <li className={"nav-item"}>
            <SidebarLink
              to={"/loyalty"}
              icon={<Loyalty className={"icon"}/>}
              title={"Loyalty Points"}
              description={
                <>
                  The <span className={"text-info"}>loyalty points</span> tool
                  shows you all of the loyalty point offers in the universe.
                </>
              }
            />
          </li>
          { /*
          <li className={"nav-item"}>
            <SidebarLink
              to={"/industry"}
              icon={<Industry className={"icon"}/>}
              title={"Industry"}
              description={
                <>
                  The <span className={"text-info"}>industry</span> tool lets you
                  manage your industry jobs.
                </>
              }
            />
          </li>
          */}
          <li className={"nav-item"}>
            <SidebarLink
              to={"/fittings"}
              icon={<Fitting className={"icon"}/>}
              title={"Fitting"}
              description={
                <>
                  The <span className={"text-info"}>fitting</span> tool lets you
                  manage your ship fittings.
                </>
              }
            />
          </li>
          <li className={"nav-item"}>
            <SidebarLink
              to={"/paste"}
              icon={<Paste className={"icon"}/>}
              title={"Paste"}
              description={
                <>
                  The <span className={"text-info"}>paste</span> tool lets you
                  paste pretty much anything copied from the game.
                </>
              }
            />
          </li>
        </ul>
        <ul className={"nav nav-pills nav-flush flex-column text-center"}>
          <li className={"nav-item"}>
            <SidebarLink
              to={"/pro"}
              icon={<Pro className={"icon icon-pro"}/>}
              title={"Pro"}
              description={
                <>
                  The <span className={"text-info"}>pro</span> page lets
                  you managed your subscription and unlock powerful features.
                  Plus, it keeps the lights on!
                </>
              }
            />
          </li>
          <li className={"nav-item"}>
            <SidebarLink
              to={"/logout"}
              icon={<Logout className={"icon"}/>}
              title={"Logout"}
              description={
                <>
                  The <span className={"text-info"}>logout</span> tool lets you
                  logout of your account.
                </>
              }
            />
          </li>
        </ul>
      </div>
      <div id={"main-content"}>
        {children ? children : <Outlet/>}
      </div>
    </>
  );
}
