import React from 'react';
import Planet from "jsx:../../icons/planet.svg";
import Characters from "jsx:../../icons/characters.svg";
import {CharacterAvatar, characterCell} from "../../components/Cells";

const eventIcons = {
  3: Planet,
  22: Characters,
  23: Characters,
};

const formatDate = (date) => {
  return new Date(date).toLocaleDateString(undefined, { month: 'long', day: 'numeric' });
};

const formatTime = (date) => {
  return new Date(date).toLocaleTimeString(undefined, { hour: '2-digit', minute: '2-digit' });
};

const EventItem = ({ event }) => {
  const Icon = eventIcons[event.category];
  const date = new Date(event.date);
  const isPast = date < new Date();

  return (
    <div className={[
      "border-start",
      "border-3",
      "ps-3",
      "d-flex",
      "flex-column",
      "flex-md-row",
      "mb-4",
      "mb-md-2",
      "align-items-start",
      "align-items-md-center",
      "align-content-center",
      isPast ? 'text-white-50' : '',
      {
        0: "border-primary",
        1: "border-warning",
        2: "border-danger",
      }[event.importance],
    ].join(" ")}>
      <div className={"flex-shrink-1"}>
        <span className={"fw-bold text-nowrap"}>{formatTime(date)}</span>
      </div>
      <div className={"flex-shrink-0 ms-2 me-2 rounded-5 p-1 d-none d-md-block"}>
        <Icon
          className={"icon p-1"}
          style={{
            fill: "white"
          }}
        />
      </div>
      <div className={"flex-grow-1"}>
        <div className={"my-2 my-md-0"}>
          {getEventDescription(event)}
        </div>
      </div>
      <div className={"flex-shrink-1 text-end"}>
        {event.context.c_name && (
          characterCell(event.context.c_id, event.context.c_name)
        )}
      </div>
    </div>
  );
};

const getEventDescription = (event) => {
  const c = event.context;
  const isPast = new Date(event.date) < new Date();
  switch (event.category) {
    case 3:
      return (
        <>
          Extractor on <span className={"text-info"}>{c.planet}</span> {isPast ? 'finished' : 'will finish'}
        </>
      );
    case 22:
      return (
        <>
          Skill <span className={"text-info"}>{c.skill_name}</span> {isPast ? 'finished' : 'will finish'} level {c.skill_level}
        </>
      );
    case 23:
      return (
        <>
          Skill queue will be <strong className={"text-warning"}>empty</strong>!
        </>
      );
    default:
      return 'Unknown event';
  }
};

const EventTimeline = ({ events }) => {
  const groupedEvents = events.reduce((acc, event) => {
    const date = new Date(event.date).toISOString().split('T')[0];
    if (!acc[date]) {
      acc[date] = [];
    }
    acc[date].push(event);
    return acc;
  }, {});

  return (
    <>
      {Object.entries(groupedEvents).map(([date, dayEvents]) => (
        <div key={date} className={"mb-4"}>
          <h3 className={`mb-4 ${new Date(date) < new Date() ? "text-white-50" : ""}`}>{formatDate(date)}</h3>
          {dayEvents.map((event) => (
            <EventItem key={event.id} event={event} />
          ))}
        </div>
      ))}
    </>
  );
};

export default EventTimeline;