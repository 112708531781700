import {Colorizer, stringToColour} from "./Colorizer";
import { Link } from "react-router-dom";
import Warning from "jsx:../icons/warning.svg";
import { isCitadel } from "./Constants";
import { FloatingPopup } from "./FloatingPopup";
import {CopyButton} from "./Copy";
import {useQuery} from "@tanstack/react-query";
import {Loading} from "./Loading";
import {useOpenInGame} from "./OpenInGame";

export function toPercent(value) {
  return `${(value * 100).toFixed(2)}%`;
}

export function security(value) {
  const sec = Math.max(Number(value), 0.0).toFixed(1);
  return (
    <span className={`${sec <= 0.0 ? "null" : sec < 0.5 ? "low" : "high"}-sec`}>
      {sec}
    </span>
  );
}

export function CompactStationLocation({ location }) {
  const triggerContent = (
    <Link to={`/universe/system/${location.system.id}`} className="btn btn-link p-0">
      {location.system.name}
    </Link>
  );

  const popupContent = (
    <dl className="mb-0 p-2">
      <dt>Station</dt>
      <dd>{location.name}</dd>
    </dl>
  );

  return (
    <>
      {security(location.system.security)}&nbsp;
      {isCitadel(location.id) && (
        <span title="This is a player-owned structure, you may not be able to dock here.">
          <Warning className="icon sm icon-warning" />
          &nbsp;
        </span>
      )}
      <FloatingPopup trigger={triggerContent} content={popupContent} />
    </>
  );
}

export function priceCell(value, show_isk = true) {
  const price = new Intl.NumberFormat("EN", {
    maximumFractionDigits: value > 1000 ? 0 : 2,
  });
  return (
    <span>
      {price.format(value)}
      {show_isk && " ISK"}
    </span>
  );
}

export function toPrice(value) {
  const price = new Intl.NumberFormat("EN", {
    maximumFractionDigits: value > 1000 ? 0 : 2,
  });
  return price.format(value);
}

export function characterCell(id, name, colorize = true) {
  return (
    <div className={"d-flex align-items-center"}>
      <img
        src={`https://images.evetech.net/characters/${id}/portrait?size=32`}
        alt={name}
        className={"me-2"}
        style={{
          borderRadius: "8px",
        }}
      />
      {colorize ? <Colorizer value={name}>{name}</Colorizer> : name}
    </div>
  );
}

/**
 * Checks if the given ID belongs to a character.
 * @param {number} id - The ID to check.
 * @returns {boolean} - True if the ID is for a character, false otherwise.
 */
function isCharacter(id) {
  return (
    (id >= 3_000_000 && id <= 4_000_000) ||
    (id >= 90_000_000 && id <= 98_000_000) ||
    (id >= 100_000_000 && id <= 2_100_000_000) ||
    (id >= 2_100_00_00 && id <= 2_147_483_647)
  );
}

/**
 * Checks if the given ID belongs to an item.
 * @param {number} id - The ID to check.
 * @returns {boolean} - True if the ID is for an item, false otherwise.
 */
function isItem(id) {
  return id >= 0 && id <= 10_000;
}

/**
 * Checks if the given ID belongs to a corporation.
 * @param {number} id - The ID to check.
 * @returns {boolean} - True if the ID is for a corporation, false otherwise.
 */
function isCorporation(id) {
  return (
    (id >= 1_000_000 && id <= 2_000_000) ||
    (id >= 98_000_000 && id <= 99_000_000)
  );
}

export function IconCell({
  id,
  name,
  isCopy = false,
  iconSize = 32,
  ...props
}) {
  let src = `https://images.evetech.net/types/${id}/icon?size=${iconSize}`;

  if (isCharacter(id)) {
    src = `https://images.evetech.net/characters/${id}/portrait?size=${iconSize}`;
  } else if (isCorporation(id)) {
    src = `https://images.evetech.net/corporations/${id}/logo?size=${iconSize}`;
  } else if (name && name.includes("SKIN")) {
    return null;
  } else if (name && name.endsWith("Blueprint")) {
    src = `https://images.evetech.net/types/${id}/bp${isCopy ? "c" : ""}?size=${iconSize}`;
  }

  return (
    <img
      src={src}
      alt={""}
      {...props}
    />
  );
}

export function TypeCell({ id, name, iconOnly = false, iconSize = 32 }) {
  return (
    <div className={"d-inline-flex align-items-center"}>
      <Link to={`/market/${id}`} className={"me-2"}>
        <IconCell id={id} name={name} iconSize={iconSize} className={"me-2"} />
        {!iconOnly && <TypeLink id={id} name={name} />}
      </Link>
    </div>
  );
}

export function TypePreview({ id }) {
  const { data, isLoading } = useQuery({
    queryKey: ["type", id],
    queryFn: () => fetch(`/api/types/${id}/`).then((res) => res.json()),
  });

  if (isLoading) {
    return <Loading />;
  }

  return (
    <table className="table table-striped table-hover mb-2" style={{
      "--bs-table-bg": "transparent",
    }}>
      <tbody>
        <tr>
          <th>
            Name
          </th>
          <td>
            <IconCell id={id} name={data.name} iconSize={64} className="icon me-2" />
            {data.name}
          </td>
        </tr>
        <tr>
          <th>Volume</th>
          <td>{data.volume} m³</td>
        </tr>
        {data.volume !== data.packaged_volume && (
          <tr>
            <th>Packaged Volume</th>
            <td>{data.packaged_volume} m³</td>
          </tr>
        )}
      </tbody>
    </table>
  );
}

export function TypeLink({ id, name }) {
  const { openInGame } = useOpenInGame();

  return (
    <FloatingPopup
      trigger={<Link to={`/market/${id}`}>{name}</Link>}
      content={
        <div onClick={(e) => e.stopPropagation()}>
          <TypePreview id={id} />
          <CopyButton onDoCopy={() => name} className={"w-100 btn btn-outline-info btn-sm mb-2"}>
            Copy Name
          </CopyButton>
          <button
            className={"btn btn-outline-info w-100 btn-sm"}
            onClick={() => openInGame("market_details", id)}
          >
            Open in Game
          </button>
        </div>
      }
    />
  )
}

export function CharacterAvatar({ id, name, colorize = true }) {
  return (
    <FloatingPopup
      trigger={
        <div className={"d-flex align-content-center align-items-center"}>
          <a href={`/characters/${id}`}>
            <img
              src={`https://images.evetech.net/characters/${id}/portrait?size=32`}
              alt={name}
              className={"border"}
              style={{
                "--bs-border-color": stringToColour(name),
              }}
            />
          </a>
        </div>
      }
      content={<Colorizer value={name}>{name}</Colorizer>}
      />
  );
}
