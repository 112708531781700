import {Content, Header, Page} from "../../components/Page";
import {AutocompleteInput} from "../components/AutocompleteInput";
import {IconCell, toPrice, TypeLink} from "../../components/Cells";
import {Link} from "react-router-dom";
import {useStorage} from "../../hooks/useStorage";
import {DataTable, useDataTable} from "../../components/DataTable";
import {compactNumber} from "../../formatting";

export function MarginSpread() {
  const [location, setLocation] = useStorage("market.margin_spread.location", null);
  
  const [state, actions] = useDataTable({
    endpoint: location ? `/api/market/margin_spread/${location.id}/` : null,
    manualFetch: true,
    defaultFilters: [],
    defaultSorting: [
      {
        id: "margin_percent",
        desc: true,
      }
    ]
  });

  const columns = [
    {
      id: "id",
      header: "",
      accessorKey: "id",
      cell: (cell) => {
        const { id, name } = cell.row.original;
        return <IconCell id={id} name={name} iconSize={32} />;
      }
    },
    {
      header: "Item",
      accessorKey: "name",
      cell: (cell) => {
        const { name, id } = cell.row.original;
        return <TypeLink id={id} name={name} />;
      }
    },
    {
      header: "Margin",
      meta: {
        align: "end",
      },
      columns: [
        {
          header: "%",
          accessorKey: "margin_percent",
          meta: {
            align: "end",
          },
          cell: (cell) => {
            const { buy, sell } = cell.row.original;
            if (buy === 0 || sell === 0) return "N/A";
            return `${((sell - buy) / buy * 100).toFixed(0)}%`;
          }
        },
        {
          header: "ISK",
          accessorKey: "margin",
          meta: {
            align: "end",
          },
          cell: (cell) => {
            const { buy, sell } = cell.row.original;
            return compactNumber(sell - buy);
          }
        }
      ]
    },
    {
      header: "Price (ISK)",
      meta: {
        align: "end",
      },
      columns: [
        {
          header: "Buy",
          accessorKey: "buy",
          meta: {
            align: "end",
          },
          cell: (cell) => toPrice(cell.getValue())
        },
        {
          header: "Sell",
          accessorKey: "sell",
          meta: {
            align: "end",
          },
          cell: (cell) => toPrice(cell.getValue())
        },
      ]
    },
    {
      header: "Volume",
      meta: {
        align: "end",
      },
      columns: [
        {
          header: "7d",
          accessorKey: "volume_7d",
          meta: {
            align: "end",
          },
          cell: (cell) => compactNumber(cell.getValue())
        },
        {
          header: (
            <abbr title={"7d_volume * sell_price"}>
              Est. Value
            </abbr>
          ),
          accessorKey: "est_value",
          meta: {
            align: "end",
          },
          cell: (cell) => compactNumber(cell.getValue())
        }
      ]
    }
  ];

  return (
    <Page>
      <Content>
        <Header
          title={"Margin Spread"}
          description={
            <>
              This tool helps you to find items with the highest margin spread. Margin
              spread is the difference between the highest buy order and the lowest sell order.
              This tool will ignore items that do not have at least 1 buy and 1 sell order.
            </>
          }
        />
        <div className="mb-3 dark">
          <AutocompleteInput
            label={"Location"}
            type={"station"}
            value={location}
            onChange={setLocation}
            inputProps={{
              placeholder: "Search for a station",
              autoFocus: true,
            }}
            helpText={"Select a station to analyze."}
          />
          <button
            onClick={actions.fetchData}
            className={"btn btn-primary w-100 mt-2"}
            disabled={state.isLoading}
          >
            {state.isLoading ? "Calculating..." : "Calculate"}
          </button>
        </div>

        {location && (
          <DataTable
            state={state}
            actions={actions}
            columns={columns}
            filtering={{
              enabled: true,
              allowedFilters: {
                group: {
                  label: "Group",
                  operators: ["&&", "!&"],
                  component: AutocompleteInput,
                  props: {
                    type: "group",
                  }
                },
                margin: {
                  label: "Margin (ISK)",
                  operators: [">=", "<="],
                },
                margin_percent: {
                  label: "Margin (%)",
                  operators: [">=", "<="],
                },
                volume_7d: {
                  label: "7 days volume",
                  operators: [">=", "<="],
                },
                est_value: {
                  label: "Estimated trade value",
                  operators: [">=", "<="],
                },
                buy: {
                  label: "Buy price",
                  operators: [">=", "<="],
                },
                sell: {
                  label: "Sell price",
                  operators: [">=", "<="],
                }
              }
            }}
          />
        )}
      </Content>
    </Page>
  );
}