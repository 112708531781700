import { useState } from "react";
import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { Colorizer } from "../../components/Colorizer";
import {CharacterAvatar, characterCell, priceCell} from "../../components/Cells";
import { Spinner } from "react-bootstrap";
import { Table } from "../../components/Table";
import { Header } from "../../components/Page";
import { ScopeBar } from "../../components/ScopeBar";

const refTypeMap = {
  transaction_tax: "Transaction Tax",
  broker_fee: "Broker Fee",
  market_transaction: "Market Transaction",
  market_escrow: "Market Escrow",
  player_trading: "Player Trading",
  contract_price: "Contract Price",
  contract_brokers_fee: "Contract Broker Fee",
  corporation_account_withdrawal: "Corp Account Withdrawal",
  structure_gate_jump: "Gate Jump",
  flux_ticket_sale: "Hypernet Ticket",
  flux_tax: "Hypernet Tax",
  flux_payout: "Hypernet Payout",
  planetary_construction: "PI Construction",
  player_donation: "Player Donation",
  skill_purchase: "Skill Purchase",
  agent_mission_reward: "Agent Mission Reward",
  agent_mission_time_bonus_reward: "Agent Mission Bonus Reward",
};

const columns = [
  {
    id: "character",
    header: "",
    accessorKey: "character",
    cell: (cell) => {
      const character = cell.getValue();
      return <CharacterAvatar id={character.id} name={character.name} />;
    },
  },
  {
    header: "Date",
    accessorKey: "date",
    cell: (cell) => {
      const value = cell.getValue();
      const date = new Date(value);
      const now = new Date();

      if (date.toDateString() === now.toDateString()) {
        return date.toLocaleTimeString();
      }
      return date.toLocaleDateString();
    },
  },
  {
    header: "Type",
    accessorKey: "ref_type",
    meta: {
      cellClasses: "w-100"
    },
    cell: (cell) => {
      const refType = cell.getValue();
      return (
        <Colorizer value={refType}>{refTypeMap[refType] || refType}</Colorizer>
      );
    },
  },
  {
    header: "Amount (ISK)",
    accessorKey: "amount",
    meta: {
      align: "end",
    },
    cell: (cell) => {
      const amount = cell.getValue();
      if (amount < 0) {
        return (
          <span className={"text-danger"}>{priceCell(amount, false)}</span>
        );
      } else {
        return (
          <span className={"text-success"}>{priceCell(amount, false)}</span>
        );
      }
    },
  },
];

export function Journal() {
  const [cursor, setCursor] = useState(null);

  const { data, isLoading } = useQuery({
    queryKey: ["journals", cursor],
    queryFn: async () => {
      const response = await fetch(
        `/api/journals/?expand=character${cursor ? `&cursor=${cursor}` : ""}`,
      );
      return response.json();
    },
    placeholderData: keepPreviousData,
  });

  if (isLoading) {
    return (
      <div className={"row"}>
        <div className={"col my-2"}>
          <div className={"text-center"}>
            <Spinner animation={"grow"} role={"status"}>
              <span className={"visually-hidden"}>Loading...</span>
            </Spinner>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <Header
        title={"Journal"}
        description={
          <>
            The journal is a record of all transactions and other actions that
            affect your wallet balance. This includes market transactions,
            contracts, taxes, and more.
          </>
        }
      />
      <div className={"mb-4"}>
        <ScopeBar scopes={["esi-wallet.read_character_wallet.v1"]} />
      </div>
      <Table rows={data.results} columns={columns} />
      <div className={"my-4"}>
        {data.previous && (
          <button
            className={"btn btn-primary me-2"}
            onClick={() =>
              setCursor(new URL(data.previous).searchParams.get("cursor"))
            }
          >
            Previous
          </button>
        )}
        {data.next && (
          <button
            className={"btn btn-primary"}
            onClick={() =>
              setCursor(new URL(data.next).searchParams.get("cursor"))
            }
          >
            Next
          </button>
        )}
      </div>
    </>
  );
}
