import {Content, Header, Page} from "../../components/Page";
import React from "react";
import {security, toPercent} from "../../components/Cells";
import {Link} from "react-router-dom";
import {AutocompleteInput} from "../components/AutocompleteInput";
import {DataTable, useDataTable} from "../../components/DataTable";

export function Universe() {
  const [state, actions] = useDataTable({
    endpoint: "/api/universe/systems/",
    fields: [
      "id",
      "name",
      "index_manufacturing",
      "index_copying",
      "index_invention",
      "index_me",
      "index_te",
      "index_reaction",
      "planet_count",
      "region.name",
      "security"
    ],
    expand: ["region"],
  });

  function costIndex(label, key) {
    return {
      id: key,
      header: label,
      accessorKey: key,
      meta: {
        align: "end",
      },
      cell: (cell) => {
        const value = cell.getValue();
        return (
          <span
            className={[
              value < 0.05 ? "text-success" : "",
              value > 0.05 && value < 0.1 ? "text-warning" : "",
              value > 0.1 ? "text-danger" : "",
            ].join(" ")}
          >
            {toPercent(value)}
          </span>
        );
      },
    };
  }

  const columns = [
    {
      header: "Name",
      accessorKey: "name",
      cell: (cell) => {
        const value = cell.getValue();
        const id = cell.row.original.id;
        return (
          <>
            {security(cell.row.original.security)}&nbsp;
            <Link to={`/universe/system/${id}/`}>{value}</Link>
          </>
        );
      }
    },
    {
      id: "region_id",
      header: "Region",
      accessorKey: "region.name",
    },
    {
      header: "Planets",
      accessorKey: "planet_count",
      meta: {
        align: "center",
      }
    },
    costIndex("Manufacturing", "index_manufacturing"),
    costIndex("Copying", "index_copying"),
    costIndex("Invention", "index_invention"),
    costIndex("ME", "index_me"),
    costIndex("TE", "index_te"),
    costIndex("Research", "index_reaction"),
  ];

  return (
    <Page>
      <Content>
        <div className="flex items-center justify-between mb-4">
          <Header
            title="Universe"
            description={
              <>
                The EVE Online universe at a glance.
              </>
            }
          />
        </div>

        <DataTable
          state={state}
          actions={actions}
          columns={columns}
          filtering={{
            enabled: true,
            allowedFilters: {
              name: {
                label: "Name",
                operators: ["~~", "!~"],
              },
              region: {
                label: "Region",
                operators: ["==", "!="],
                component: AutocompleteInput,
                props: {
                  type: "regions"
                }
              },
              planets: {
                label: "Planets",
                operators: ["<<", "<=", "==", "!=", ">=", ">>"],
              },
              security: {
                label: "Security Status",
                operators: ["<<", "<=", "==", "!=", ">=", ">>"],
              }
            }
          }}
        />
      </Content>
    </Page>
  );
}