import { useQuery } from "@tanstack/react-query";
import { Table } from "../../components/Table";
import { Modal, Spinner } from "react-bootstrap";
import { useState } from "react";
import {
  CharacterAvatar,
  characterCell, IconCell,
  priceCell,
  TypeCell, TypeLink,
} from "../../components/Cells";
import { Header } from "../../components/Page";
import { ScopeBar } from "../../components/ScopeBar";

import Search from "jsx:../../icons/search.svg";
import {applyFiltersToURL, FilterBuilder, filtersToQuery} from "../../components/FilterBuilder";
import {AutocompleteInput} from "../components/AutocompleteInput";
import {useStorage} from "../../hooks/useStorage";
import {Loading} from "../../components/Loading";
import {Link} from "react-router-dom";


function TradeDetails ({ id, onHide }) {
  const { data, isLoading } = useQuery({
    queryKey: ["trading.details", id],
    queryFn: () => {
      const params = new URLSearchParams();
      params.set(
        "expand",
        [
          "character",
          "item",
          "location",
          "counterpart",
          "counterpart.character",
          "counterpart.location",
          "transaction",
          "counterpart.transaction",
        ].join(","),
      );
      return fetch(`/api/movements/${id}/?${params.toString()}`).then((res) => res.json());
    },
  });

  if (isLoading) {
    return <Loading />;
  }

  // If present (not always) this is the order/industry job/whatever that acquired the object.
  const counterpart = data.counterpart;
  const profit = counterpart ? (data.price - counterpart.price) : null;

  return (
    <Modal size={"lg"} show={true} onHide={onHide} fullscreen={true}>
      <Modal.Body>
        <div className={"d-flex align-items-center align-content-center mb-3"}>
          <img src={`https://images.evetech.net/types/${data.item.id}/icon?size=64`} alt={data.item.name} className={"me-3"}/>
          <div className={"d-flex flex-column flex-grow-1"}>
            <Link to={`/market/${data.item.id}`} className={"h3"}>
              {data.item.name}
            </Link>
            Quantity: {data.quantity}
          </div>

        </div>
        <div className={"row"}>
          <div className={"col"}>
            <div className={"card card-block"}>
              <div className={"card-header"}>
                Acquisition
              </div>
              <div className={"card-body"}>
                {counterpart ? (
                  <dl>
                    <dt>Price</dt>
                    <dd>
                      {priceCell(counterpart.price)}
                    </dd>
                    <dt>When</dt>
                    <dd>
                      {new Date(counterpart.when).toLocaleString()}
                    </dd>
                    <dt>Where</dt>
                    <dd>
                      {counterpart.location?.name}
                    </dd>
                    <dt>Acquired By</dt>
                    <dd>
                      <div className={"d-flex align-items-center"}>
                        {characterCell(
                          counterpart.character.id,
                          counterpart.character.name,
                        )}
                      </div>
                    </dd>
                  </dl>
              ) : (
                <div className={"alert alert-warning"}>
                  This item was acquired through an unknown source.
                </div>
              )}
              </div>
            </div>
          </div>
          <div className={"col"}>
            <div className={"card card-block"}>
              <div className={"card-header"}>
                Sale
              </div>
              <div className={"card-body"}>
                <dl>
                  <dt>Price</dt>
                  <dd className={"d-flex"}>
                    <div className={"flex-grow-1"}>
                      {priceCell(data.price)}
                    </div>
                    {profit && (
                      <span className={`ps-3 text-${profit >= 0 ? "success" : "danger"}`}>
                        {profit >= 0 ? "+" : "-"}{Math.abs(profit)} ISK
                        ({((profit / counterpart.price) * 100).toFixed(2)})%
                      </span>
                    )}
                  </dd>
                  <dt>When</dt>
                  <dd className={"d-flex"}>
                    <div className={"flex-grow-1"}>
                      {new Date(data.when).toLocaleString()}
                    </div>
                    {counterpart && (
                      <div className={"text-white-50"}>
                        Owned for {Math.floor((new Date(data.when) - new Date(counterpart.when)) / 86400000)} days.
                      </div>
                    )}
                  </dd>
                  <dt>Where</dt>
                  <dd>
                    {data.location?.name}
                  </dd>
                  <dt>Sold By</dt>
                  <dd>
                    <div className={"d-flex align-items-center"}>
                      {characterCell(data.character.id, data.character.name)}
                    </div>
                  </dd>
                </dl>
              </div>
            </div>
          </div>
        </div>
        <div className={"mt-3"}>
          <h3 className={"mb-3"}>Similar Trades</h3>
          <TradeTable filters={[
            {type: "item", operator: "==", value: data.item.id},
            {type: "direction", operator: "==", value: 20},
          ]} />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button className={"btn btn-primary w-100"} onClick={onHide}>
          Close
        </button>
      </Modal.Footer>
    </Modal>
  );
}

export function TradeTable ({ filters }) {
  const [cursor, setCursor] = useState(null);
  const [details, setDetails] = useState(null);

  const {data, isLoading} = useQuery({
    queryKey: ["trading", cursor, filtersToQuery(filters)],
    queryFn: () => {
      const params = new URLSearchParams();
      params.set(
        "expand",
        [
          "character",
          "item",
          "location",
          "counterpart",
          "counterpart.character",
          "counterpart.location",
        ].join(","),
      );

      applyFiltersToURL(params, [
        {type: "direction", operator: "==", value: 20},
        ...filters
      ]);

      if (cursor) {
        params.set("cursor", cursor);
      }

      return fetch(`/api/movements/?${params.toString()}`).then((res) =>
        res.json(),
      );
    },
  });

  const columns = [
    {
      id: "character",
      header: "",
      accessorKey: "character",
      cell: (cell) => {
        const {character} = cell.row.original;
        return <CharacterAvatar id={character.id} name={character.name}/>;
      }
    },
    {
      header: "Date",
      accessorKey: "when",
      meta: {
        align: "end",
      },
      cell: (cell) => {
        const {when} = cell.row.original;
        const date = new Date(when);
        return date.toLocaleDateString();
      },
    },
    {
      id: "type_id",
      header: "",
      accessorKey: "item",
      cell: (cell) => {
        const {item} = cell.row.original;
        return <IconCell id={item.id} name={item.name} iconSize={32}/>;
      }
    },
    {
      header: "Type",
      accessorKey: "item",
      meta: {
        cellClasses: "w-100",
      },
      cell: (cell) => {
        const item = cell.getValue();
        return <TypeLink id={item.id} name={item.name}/>;
      },
    },
    {
      header: "Qty",
      accessorKey: "quantity",
      meta: {
        align: "center",
      },
    },
    {
      header: "Buy",
      accessorKey: "counterpart.price",
      meta: {
        align: "center",
      },
      cell: (cell) => {
        const {counterpart} = cell.row.original;

        if (!counterpart) {
          return "-";
        }

        return priceCell(counterpart.price, false);
      },
    },
    {
      header: "Sell",
      accessorKey: "price",
      meta: {
        align: "center",
      },
      cell: (cell) => {
        const {price, counterpart} = cell.row.original;

        if (counterpart && parseFloat(price) > parseFloat(counterpart.price)) {
          return (
            <span className={"text-success"}>
              {priceCell(price, false)}
            </span>
          )
        } else if (!counterpart) {
          return (
            <abbr className={"text-warning"} title={"No known source price"}>
              {priceCell(price, false)}
            </abbr>
          )
        } else {
          return (
            <span className={"text-danger"}>
              {priceCell(price, false)}
            </span>
          )
        }
      }
    },
    {
      header: "%",
      meta: {
        align: "center",
      },
      cell: (cell) => {
        const {price, counterpart} = cell.row.original;

        if (!counterpart) {
          return "-";
        }

        const profit = ((price - counterpart.price) / counterpart.price) * 100;

        return (
          <span className={`text-${profit >= 0 ? "success" : "danger"}`}>
            {profit >= 0 ? "+" : "-"}{Math.abs(profit).toFixed(2)}%
          </span>
        );
      },
    },

    {
      header: "",
      id: "actions",
      cell: (cell) => {
        return (
          <button
            className={"btn btn-link"}
            onClick={() => setDetails(cell.row.original)}
          >
            <Search className={"icon sm"}/>
          </button>
        );
      },
    },
  ];

  return (
    <div>
      <Table
        defaultColumn={{enableSorting: false}}
        rows={data?.results || []}
        columns={columns}
        isLoading={isLoading}
      />
      <div className={"my-4"}>
        {data?.previous && (
          <button
            className={"btn btn-primary me-2"}
            onClick={() =>
              setCursor(new URL(data.previous).searchParams.get("cursor"))
            }
          >
            Previous
          </button>
        )}
        {data?.next && (
          <button
            className={"btn btn-primary"}
            onClick={() =>
              setCursor(new URL(data.next).searchParams.get("cursor"))
            }
          >
            Next
          </button>
        )}
      </div>
      {details && <TradeDetails id={details.id} onHide={() => setDetails(null)}/>}
    </div>
  );
}


export function Trading() {
  const [filters, setFilters] = useStorage("trading.filters", []);

  return (
    <>
      <Header
        title={"Trade Tracker"}
        description={
          <>
            The <strong>Trade Tracker</strong> view shows all of the market
            transactions that your character has made to sell things you've{" "}
            <strong>bought from the market</strong> or{" "}
            <strong>built with industry</strong>. Items that have been bought
            but not sold yet do not appear here.
          </>
        }
      />
      <div className={"mb-4"}>
        <ScopeBar scopes={["esi-wallet.read_character_wallet.v1"]}/>
      </div>
      <div className={"mb-3"}>
        <FilterBuilder
          onChange={setFilters}
          filters={filters}
          allowedFilters={{
            location: {
              label: "Location",
              operators: ["==", "!="],
              component: AutocompleteInput,
              props: {
                type: "location",
              },
            },
            item: {
              label: "Item",
              operators: ["==", "!="],
              component: AutocompleteInput,
              props: {
                type: "type",
              },
            },
            character: {
              label: "Character",
              operators: ["==", "!="],
              component: AutocompleteInput,
              props: {
                type: "character",
              },
            },
          }}
        />
      </div>
      <TradeTable filters={filters}/>
    </>
  );
}
