import { useState } from "react";
import { keepPreviousData, useQuery } from "@tanstack/react-query";
import {
  CharacterAvatar,
  characterCell,
  CompactStationLocation,
  IconCell,
  priceCell,
  TypeCell, TypeLink
} from "../../components/Cells";
import { OverlayTrigger, Popover, Spinner } from "react-bootstrap";
import { Table } from "../../components/Table";
import { Header } from "../../components/Page";
import { ScopeBar } from "../../components/ScopeBar";
import { Loading } from "../../components/Loading";
import {applyFiltersToURL, FilterBuilder, filtersToQuery} from "../../components/FilterBuilder";
import {AutocompleteInput} from "../components/AutocompleteInput";
import {useStorage} from "../../hooks/useStorage";

const columns = [
  {
    id: "character",
    header: "",
    accessorKey: "character",
    cell: (cell) => {
      const character = cell.getValue();
      return <CharacterAvatar id={character.id} name={character.name} />;
    },
  },
  {
    header: "Date",
    accessorKey: "date",
    meta: {
      align: "end"
    },
    cell: (cell) => {
      const value = cell.getValue();
      const date = new Date(value);
      const now = new Date();

      if (date.toDateString() === now.toDateString()) {
        return date.toLocaleTimeString();
      }
      return date.toLocaleDateString();
    },
  },
  {
    header: "Quantity",
    accessorKey: "quantity",
    meta: {
      align: "end",
    },
  },
  {
    id: "type_id",
    header: "",
    accessorKey: "item",
    cell: (cell) => {
      const {item} = cell.row.original;
      return <IconCell id={item.id} name={item.name} iconSize={32}/>;
    }
  },
  {
    header: "Type",
    accessorKey: "item",
    meta: {
      cellClasses: "w-100"
    },
    cell: (cell) => {
      const item = cell.getValue();
      return <TypeLink id={item.id} name={item.name} />;
    },
  },
  {
    header: "Total (ISK)",
    accessorFn: (row) => row.quantity * row.unit_price,
    meta: {
      align: "end",
    },
    cell: (cell) => {
      const value = cell.getValue();
      const original = cell.row.original;

      return (
        <OverlayTrigger
          overlay={
            <Popover>
              <Popover.Body>
                <dl>
                  <dt>Per Unit Price</dt>
                  <dd>{priceCell(original.unit_price)}</dd>
                </dl>
              </Popover.Body>
            </Popover>
          }
        >
          <span
            className={[
              original.is_buy ? "text-danger" : "text-success",
              "cursor-pointer",
            ].join(" ")}
          >
            {priceCell(value, false)}
          </span>
        </OverlayTrigger>
      );
    },
  },
  {
    header: "Where",
    accessorKey: "location",
    meta: {
      nowrap: true,
    },
    cell: (cell) => {
      return <CompactStationLocation location={cell.getValue()} />;
    },
  }
];


export function TransactionsTable({ filters }) {
  const [cursor, setCursor] = useState(null);

  const { data, isLoading } = useQuery({
    queryKey: ["transactions", filtersToQuery(filters), cursor],
    queryFn: () => {
      const params = new URLSearchParams();
      params.set("expand", ["character", "item", "location", "location.system"].join(","));
      params.set(
        "fields",
        [
          "id",
          "is_buy",
          "client_id",
          "quantity",
          "date",
          "item.id",
          "item.name",
          "character.id",
          "character.name",
          "unit_price",
          "location.id",
          "location.name",
          "location.system.id",
          "location.system.name",
          "location.system.security"
        ].join(","),
      );

      applyFiltersToURL(params, filters);

      if (cursor) {
        params.append("cursor", cursor);
      }

      return fetch(`/api/transactions/?${params.toString()}`).then((res) => res.json());
    },
    placeholderData: keepPreviousData,
  });

  return (
    <div>
      <Table
        rows={data?.results || []}
        columns={columns}
        isLoading={isLoading}
      />
      <div className={"my-4"}>
        {data?.previous && (
          <button
            className={"btn btn-primary me-2"}
            onClick={() =>
              setCursor(new URL(data.previous).searchParams.get("cursor"))
            }
          >
            Previous
          </button>
        )}
        {data?.next && (
          <button
            className={"btn btn-primary"}
            onClick={() =>
              setCursor(new URL(data.next).searchParams.get("cursor"))
            }
          >
            Next
          </button>
        )}
      </div>
    </div>
  );
}


export function Transactions() {
  const [filters, setFilters] = useStorage("transactions.filters", []);

  return (
    <>
      <Header
        title={"Transactions"}
        description={
          <>
            Transactions are the records of all the items you have bought and
            sold on the market.
          </>
        }
      />
      <div className={"mb-4"}>
        <ScopeBar scopes={["esi-wallet.read_character_wallet.v1"]} />
      </div>
      <div className={"mb-3"}>
        <FilterBuilder
          onChange={setFilters}
          filters={filters}
          allowedFilters={{
            location: {
              label: "Location",
              operators: ["==", "!="],
              component: AutocompleteInput,
              props: {
                type: "location",
              },
            },
            item: {
              label: "Item",
              operators: ["==", "!="],
              component: AutocompleteInput,
              props: {
                type: "type",
              },
            },
            character: {
              label: "Character",
              operators: ["==", "!="],
              component: AutocompleteInput,
              props: {
                type: "character",
              },
            },
          }}
        />
      </div>
      <TransactionsTable filters={filters} />
    </>
  );
}
