import {Content, Header, Page} from "../../components/Page";
import {Block, BlockBody, BlockFooter, BlockHeader} from "../../components/Block";
import {Link} from "react-router-dom";

export function Economy () {
  return (
    <Page>
      <Content>
        <Header
          title={"Economy"}
          description={
            <>
              Tools to help you analyze the economy of New Eden.
            </>
          }
        />
        <div className={"row row-cols-1 row-cols-lg-2 row-cols-xl-3 row-cols-xxl-4 g-4"}>
          <div className={"col"}>
            <Block className={"h-100"}>
              <BlockHeader>
                Arbitrage
              </BlockHeader>
              <BlockBody>
                Find items that can be bought from outstanding sell orders
                in one station and sold to buy orders in another station
                for a profit.
              </BlockBody>
              <BlockFooter>
                <Link to={"/economy/arbitrage"} className={"btn btn-primary w-100"}>
                  Go
                </Link>
              </BlockFooter>
            </Block>
          </div>
          <div className={"col"}>
            <Block className={"h-100"}>
              <BlockHeader>
                Margin Spread
              </BlockHeader>
              <BlockBody>
                This tool helps you to find items with the highest margin spread. Margin
                spread is the difference between the highest buy order and the lowest sell order.
              </BlockBody>
              <BlockFooter>
                <Link to={"/economy/margin-spread"} className={"btn btn-primary w-100"}>
                  Go
                </Link>
              </BlockFooter>
            </Block>
          </div>
          <div className={"col"}>
            <Block className={"h-100"}>
              <BlockHeader>
                Station Demand
              </BlockHeader>
              <BlockBody>
                Find items that may be in high demand at a specific station.
              </BlockBody>
              <BlockFooter>
                <Link to={"/economy/station-demand"} className={"btn btn-primary w-100"}>
                  Go
                </Link>
              </BlockFooter>
            </Block>
          </div>
          <div className={"col"}>
            <Block className={"h-100"}>
              <BlockHeader>
                Segment
              </BlockHeader>
              <BlockBody>
                Analyze a specific market segment, such as ships or minerals, in a specific region.
              </BlockBody>
              <BlockFooter>
                <Link to={"/economy/segment"} className={"btn btn-primary w-100"}>
                  Go
                </Link>
              </BlockFooter>
            </Block>
          </div>
        </div>
      </Content>
    </Page>
  )
}