import { useAuth } from "../hooks/useAuth";
import Positive from "jsx:../icons/positive.svg";
import { Link } from "react-router-dom";
import {FloatingPopup} from "./FloatingPopup";

/**
 * Shows a bar with all characters and the status of their scopes.
 *
 * @param scopes Scopes that must be present for functionality.
 * @param optional Scopes that are optional for functionality.
 * @returns {JSX.Element|null}
 * @constructor
 */
export function ScopeBar({ scopes, optional }) {
  const { user } = useAuth();

  if (!user) {
    return null;
  }

  const results = user.characters.map((character) => {
    return {
      id: character.character,
      name: character.name,
      scopes: character.scopes,
      enabled:
        scopes?.filter((scope) => {
          return character.scopes.includes(scope);
        }) || [],
      warning:
        optional?.filter((scope) => {
          return !character.scopes.includes(scope);
        }) || [],
      disabled:
        scopes?.filter((scope) => {
          return !character.scopes.includes(scope);
        }) || [],
    };
  }).sort((a, b) => {
    return a.name.localeCompare(b.name);
  });

  return (
    <div
      className={"d-flex flex-row flex-wrap align-items-center flex"}
      style={{
        rowGap: "10px",
      }}
    >
      {results.map((result) => {
        return (
          <div className={"d-inline-block"} key={result.id}>
            <FloatingPopup
              trigger={
                <form method={"post"} action={"/api/auth/login"} className={"d-inline"}>
                  <input type={"hidden"} name={"scopes"} value={[
                    ...result.scopes,
                    ...result.disabled,
                    ...result.warning
                  ].join(",")}/>
                  <button type={"submit"} className={"btn btn-link p-0"} title={result.name} disabled={
                    result.disabled.length === 0 && result.warning.length === 0
                  }>
                    <img
                      src={`https://images.evetech.net/characters/${result.id}/portrait?size=32`}
                      alt={result.name}
                      className={[
                        "me-2",
                        "border",
                        "border-1",
                        result.disabled.length > 0
                          ? "border-danger"
                          : result.warning.length > 0
                            ? "border-warning"
                            : "border-success",
                      ].join(" ")}
                      title={""}
                    />
                  </button>
                </form>
              }
              content={
                <>
                  <div className={"text-info"}>{result.name}</div>
                  {result.warning.length > 0 && (
                    <>
                        <span>
                          Some optional scopes to use this feature are missing:
                        </span>
                      <ul className={"m-0 list-unstyled"}>
                        {result.warning.map((scope) => {
                          return (
                            <li key={scope} className={"text-warning"}>
                              {scope}
                            </li>
                          );
                        })}
                      </ul>
                    </>
                  )}
                  {result.disabled.length > 0 && (
                    <>
                        <span>
                          Some required scopes to use this feature are missing:
                        </span>
                      <ul className={"m-0 list-unstyled"}>
                        {result.disabled.map((scope) => {
                          return (
                            <li key={scope} className={"text-danger"}>
                              {scope}
                            </li>
                          );
                        })}
                      </ul>
                    </>
                  )}
                  {result.warning.length > 0 || result.disabled.length > 0 ? (
                    <span>Click the avatar to add these permissions.</span>
                  ) : (
                    <span>All scopes to use this feature are granted.</span>
                  )}
                </>
              }
            />
          </div>
        );
      })}
      <div className={"d-inline-block"}>
        <FloatingPopup
          trigger={
            <Link to={"/login"} title={""}>
              <Positive className={"icon sm"} />
            </Link>
          }
          content={
            <>
              <div className={"text-info"}>Add Character</div>
              <span>
                Click here to add another character or update permissions for an
                existing one.
              </span>
            </>
          }
        />
      </div>
    </div>
  );
}
