import { useQuery } from "@tanstack/react-query";
import { useCallback, useMemo, useState } from "react";
import { priceCell, TypeCell } from "../../components/Cells";
import { Table } from "../../components/Table";
import {useNavigate, useParams} from "react-router-dom";
import { compactNumber, humanizeRelativeDate } from "../../formatting";
import { savePaste } from "../../mutations/paste";
import { Loading } from "../../components/Loading";
import {Bottom, Top} from "../../components/Page";
import {useAuth} from "../../hooks/useAuth";
import {useOpenInGame} from "../../components/OpenInGame";

export function ContractPage() {
  const { slug } = useParams();

  return (
      <ContractViewer contractSlug={slug} />
  );
}

export function ContractViewer({ contractSlug }) {
  const [includedSorting, setIncludedSorting] = useState([{ id: "price", desc: true }]);
  const [excludedSorting, setExcludedSorting] = useState([{ id: "price", desc: true }]);
  const { openInGame } = useOpenInGame();

  const { user } = useAuth();
  const { data: contract, isLoading, isError} = useQuery({
    queryKey: ["contract", contractSlug],
    queryFn: () => {
      const url = new URL(`/api/contracts/${contractSlug}/`, window.location.origin);
      url.searchParams.append(
        "expand",
        [
          "items",
          "items.item",
          "items.item.fair_price",
          "bids",
          "issuer",
          "start_location",
        ].join(","),
      );

      return fetch(url).then(
        (res) => {
          if (res.status === 404) {
            throw new Error("Not found");
          }
          return res.json();
        }
      );
    },
  });

  const columns = useMemo(
    () => [
      {
        id: "item",
        header: "Item",
        accessorKey: "item",
        cell: (cell) => {
          const value = cell.getValue();
          const row = cell.row.original;

          return (
            <div className={"d-flex flex-row align-items-center"}>
              <div className={"flex-grow-1"}>
                <TypeCell id={value.id} name={value.name} />
              </div>
              {row.is_blueprint_copy && (
                <span className={"badge text-bg-info ms-1"}>Copy</span>
              )}
              {row.material_efficiency !== null && (
                <span className={"badge bg-secondary ms-1"}>
                  ME {row.material_efficiency}
                </span>
              )}
              {row.time_efficiency !== null && (
                <span className={"badge bg-secondary ms-1"}>
                  TE {row.time_efficiency}
                </span>
              )}
              {row.runs !== null && (
                <span className={"badge bg-secondary ms-1"}>
                  {row.runs} runs
                </span>
              )}
            </div>
          );
        },
      },
      {
        id: "price",
        header: "Est. Value (ISK)",
        // accessorKey: "item",
        accessorFn: (row) => {
          if (row.item.fair_price.length > 0) {
            return row.item.fair_price[0].sell * row.quantity;
          }
          return 0;
        },
        meta: {
          align: "end",
          nowrap: true,
        },
        cell: (cell) => {
          return priceCell(cell.getValue());
        },
        footer: () => {
          return (
            <span>
              {priceCell(
                contract.items.reduce((acc, item) => {
                  if (item.item.fair_price.length > 0) {
                    return acc + item.item.fair_price[0].sell * item.quantity;
                  }
                  return acc;
                }, 0),
                false,
              )}
            </span>
          );
        },
      },
      {
        header: "Qty",
        accessorKey: "quantity",
        meta: {
          align: "end",
          nowrap: true,
        },
      },
      {
        id: "volume",
        header: "Volume",
        accessorKey: "item",
        meta: {
          align: "end",
          nowrap: true,
        },
        cell: (cell) => {
          const value = cell.getValue();
          return <span>{(value.volume * cell.row.original.quantity).toFixed()}</span>;
        },
      },
    ],
    [contract],
  );

  const navigate = useNavigate();
  const saveMutation = savePaste({
    onSuccess: (data) => {
      navigate(`/paste/${data.slug}`);
    },
  });

  // Takes the contents of a contract and creates an appraisal from it.
  // Only works for the WTS part of a contract, for the moment.
  const createAppraisal = useCallback(
    (e) => {
      e.target.disabled = true;

      saveMutation.mutate({
        content: contract.items
          .map((item) => {
            const type = item.item;
            return `${type.name} ${item.quantity}`;
          })
          .join("\n"),
        region: 10000002,
      });
    },
    [contract],
  );

  if (isLoading) {
    return <Loading />;
  }

  if (isError) {
    return (
      <div className={"alert alert-warning"} role={"alert"}>
        Could not find the contract you were looking for. It may
        have expired, purchased, or cancelled.
      </div>
    )
  }

  return (
    <div className={"d-flex flex-column vh-100"}>
      <Top className={"p-3"}>
        <h1 className={"fs-3 mb-4"}>
          {contract.title ||
            (contract.items.length > 1
              ? "[ Multiple Items ]"
              : contract.items[0].item.name)}
        </h1>
        <div className={"row"}>
          <div className={"col-md-6 col"}>
            <dl className={"feature"}>
              <dt>Issuer</dt>
              <dd>
                {contract.issuer && contract.issuer.corporation_id && (
                  <img
                    src={`https://images.evetech.net/corporations/${contract.issuer.corporation_id}/logo?size=32`}
                    alt={contract.issuer.name} className={"me-2"}
                  />
                )}
                {contract.issuer ? contract.issuer.name : <em>Unknown</em>}
              </dd>
              <dt>Location</dt>
              <dd>{contract.start_location.name}</dd>
              <dt>Price</dt>
              <dd>
                {priceCell(
                  contract.reward > 0
                    ? contract.reward * -1
                    : contract.price,
                )}
              </dd>
              <dt>Total Volume</dt>
              <dd>
                {compactNumber(contract.volume)} m<sup>3</sup>
              </dd>
              <dt>Issued</dt>
              <dd>{contract.date_issued}</dd>
              <dt>Expires</dt>
              <dd>{humanizeRelativeDate(contract.date_expired)}</dd>
            </dl>
          </div>
        </div>
        {contract.bids.length > 0 && (
          <div className={"mb-4"}>
            <h2 className={"fs-5"}>Bids</h2>
            <Table
              columns={[
                {
                  header: "Bid On",
                  accessorKey: "date_bid",
                },
                {
                  header: "Amount",
                  accessorKey: "amount",
                  meta: {
                    align: "end",
                    nowrap: true,
                  },
                  cell: (cell) => priceCell(cell.getValue()),
                },
              ]}
              rows={contract.bids}
            />
          </div>
        )}
        {contract.items.some((item) => item.is_included) && (
          <div className={"mb-4"}>
            <h2 className={"fs-5"}>You Get</h2>
            <Table
              sorting={includedSorting}
              setSorting={setIncludedSorting}
              columns={columns}
              rows={contract.items.filter((item) => item.is_included)}
              showFooter={true}
            />
          </div>
        )}
        {contract.items.some((item) => !item.is_included) && (
          <div className={"mb-4"}>
            <h2 className={"fs-5"}>You Give</h2>
            <Table
              sorting={excludedSorting}
              setSorting={setExcludedSorting}
              columns={columns}
              rows={contract.items.filter((item) => !item.is_included)}
            />
          </div>
        )}
      </Top>
      {user && (
        <Bottom className={"p-3"}>
          <button
            className={"btn btn-outline-info w-100 mb-2"}
            onClick={createAppraisal}
          >
            Create Appraisal from Contract
          </button>
          <button
            className={"btn btn-outline-info w-100"}
            onClick={() => openInGame("contract", contract.id)}
          >
            Open In-Game
          </button>
        </Bottom>
      )}
    </div>
  );
}
