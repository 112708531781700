import React, { useState } from "react";
import {
  useHover,
  useInteractions,
  safePolygon,
  FloatingPortal,
  autoUpdate,
  useFloating,
  autoPlacement,
  shift,
} from "@floating-ui/react";

export const FloatingPopup = ({ trigger, content }) => {
  const [isOpen, setIsOpen] = useState(false);

  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    whileElementsMounted: autoUpdate,
    middleware: [
      shift(),
      autoPlacement({
        padding: 8,
      })
    ],
  });

  const hover = useHover(context, {
    handleClose: safePolygon(),
  });

  const { getReferenceProps, getFloatingProps } = useInteractions([hover]);

  return (
    <>
      <div
        className={"d-inline-block"}
        ref={refs.setReference}
        {...getReferenceProps()}
      >
        {trigger}
      </div>
      {isOpen && (
        <FloatingPortal>
          <div
            ref={refs.setFloating}
            style={floatingStyles}
            className={`cosmic-popup`}
            {...getFloatingProps()}
          >
            <div className="cosmic-popup-content">{content}</div>
          </div>
        </FloatingPortal>
      )}
    </>
  );
};
