import { useState, useCallback, useMemo, useEffect, ReactNode } from 'react';
import { useQuery } from '@tanstack/react-query';
import type { ColumnDef, ExpandedState, SortingState } from '@tanstack/react-table';
import { Table } from './Table';
import { FilterBuilder, applyFiltersToURL, applySortingToURL } from './FilterBuilder';

export type Filter = {
  field: string;
  operator: string;
  value: string | number | boolean;
};

export type AllowedFilterConfig = {
  label: string;
  operators: string[];
  component?: any;
  props?: Record<string, any>;
  options?: { label: string; value: string | number }[];
};

export type AllowedFilters = Record<string, AllowedFilterConfig>;

type DataTableState<T> = {
  data: T[];
  isLoading: boolean;
  filters: Filter[];
  appliedFilters: Filter[];
  sorting: SortingState;
  expanded: ExpandedState;
  pagination: {
    hasNext: boolean;
    hasPrevious: boolean;
  };
};

type UseDataTableOptions<T> = {
  endpoint?: string;
  initialData?: T[] | { results: T[]; next: string | null; previous: string | null };
  fields?: string[];
  expand?: string[];
  defaultFilters?: Filter[];
  defaultSorting?: SortingState;
  manualFetch?: boolean;
  onFetchComplete?: () => void;
};

export function useDataTable<T extends object>(options: UseDataTableOptions<T>) {
  const {
    endpoint,
    initialData,
    fields,
    expand,
    defaultFilters = [],
    defaultSorting = [],
    manualFetch = false,
    onFetchComplete,
  } = options;

  const [url, setURL] = useState<string>();
  const [filters, setFilters] = useState(defaultFilters);
  const [appliedFilters, setAppliedFilters] = useState(defaultFilters);
  const [sorting, setSorting] = useState(defaultSorting);
  const [expanded, setExpanded] = useState<ExpandedState>({});
  const [shouldFetch, setShouldFetch] = useState(!manualFetch);

  const dataURL = useMemo(() => {
    if (!endpoint) return null;
    const params = new URLSearchParams();

    if (fields?.length) params.append("fields", fields.join(","));
    if (expand?.length) params.append("expand", expand.join(","));
    if (appliedFilters?.length) applyFiltersToURL(params, appliedFilters);
    if (sorting?.length) applySortingToURL(params, sorting);

    return `${endpoint}?${params.toString()}`;
  }, [endpoint, fields, expand, appliedFilters, sorting]);

  const { data: fetchedData, isLoading, refetch } = useQuery({
    queryKey: ["data", url, dataURL],
    queryFn: async () => {
      if (!dataURL) return { results: [] };
      const res = await fetch(url || dataURL);
      if (!res.ok) throw new Error('Network response was not ok');
      return res.json();
    },
    enabled: !!endpoint && !!dataURL && shouldFetch,
    staleTime: Infinity,
  });

  const finalData = useMemo(() => (
    initialData ?
      Array.isArray(initialData) ? initialData : initialData.results :
      fetchedData?.results || []
  ), [initialData, fetchedData]);

  useEffect(() => {
    if (!manualFetch) {
      setAppliedFilters(filters);
      setShouldFetch(true);
      setURL(undefined);
      refetch().then(() => onFetchComplete?.());
    }
  }, [filters, manualFetch, refetch, onFetchComplete]);

  const actions = {
    setFilters,
    setSorting: useCallback((newSorting: SortingState) => {
      if (!isLoading) setSorting(newSorting);
    }, [isLoading]),
    setExpanded,
    fetchData: useCallback(async () => {
      setAppliedFilters(filters);
      setShouldFetch(true);
      setURL(undefined);
      await refetch();
      onFetchComplete?.();
    }, [refetch, filters, onFetchComplete]),
    goToNextPage: () => fetchedData?.next && setURL(fetchedData.next),
    goToPreviousPage: () => fetchedData?.previous && setURL(fetchedData.previous),
  };

  return [{
    data: finalData,
    isLoading,
    filters,
    appliedFilters,
    sorting,
    expanded,
    pagination: {
      hasNext: !!fetchedData?.next,
      hasPrevious: !!fetchedData?.previous,
    },
  }, actions] as const;
}

type DataTableProps<T extends object> = {
  state: DataTableState<T>;
  actions: ReturnType<typeof useDataTable<T>>[1];
  columns: ColumnDef<T>[];
  className?: string;
  filtering?: {
    enabled?: boolean;
    allowedFilters?: AllowedFilters;
  };
  expandable?: {
    enabled?: boolean;
    renderSubComponent?: (props: { row: T }) => ReactNode;
  };
  rowClassName?: (row: T) => string | undefined;
  defaultColumn?: Partial<ColumnDef<T>>;
};

export function DataTable<T extends object>({
  state,
  actions,
  columns,
  className,
  filtering,
  expandable,
  rowClassName,
  defaultColumn,
}: DataTableProps<T>) {
  return (
    <div className={className}>
      {filtering?.enabled && filtering.allowedFilters && (
        <FilterBuilder
          filters={state.filters}
          onChange={actions.setFilters}
          allowedFilters={filtering.allowedFilters}
        />
      )}

      <Table
        rows={state.data}
        columns={columns}
        isLoading={state.isLoading}
        sorting={state.sorting}
        setSorting={actions.setSorting}
        defaultColumn={defaultColumn}
        rowClasses={rowClassName}
        expanded={expandable?.enabled ? state.expanded : undefined}
        onExpandedChange={expandable?.enabled ? actions.setExpanded : undefined}
        getRowCanExpand={expandable?.enabled ? () => true : undefined}
        renderSubComponent={expandable?.renderSubComponent}
        manualSorting={false}
        enableSorting={!state.isLoading}
      />

      {(state.pagination.hasNext || state.pagination.hasPrevious) && (
        <div className="btn-group my-4">
          {state.pagination.hasPrevious && (
            <button className="btn btn-primary me-2" onClick={actions.goToPreviousPage}>
              Previous
            </button>
          )}
          {state.pagination.hasNext && (
            <button className="btn btn-primary" onClick={actions.goToNextPage}>
              Next
            </button>
          )}
        </div>
      )}
    </div>
  );
}