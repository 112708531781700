import React from 'react';

const FormGroup = ({ children, className = "", error, helpText }) => (
  <div className={`form-group mb-3 ${className}`}>
    {children}
    {error ? (
      <div className="invalid-feedback">{error.message}</div>
    ) : null}
    {helpText ? (
      <small className="form-text text-white-50">{helpText}</small>
    ) : null}
  </div>
);

const FormLabel = ({ htmlFor, children }) => (
  <label className="form-label" htmlFor={htmlFor}>
    {children}
  </label>
);

export const Input = ({
  register,
  label,
  type = "text",
  name,
  errors,
  helpText,
  required = false,
  className = "",
  ...props
}) => {
  return (
    <FormGroup error={errors[name]} helpText={helpText}>
      <FormLabel htmlFor={name}>{label}</FormLabel>
      <input
        type={type}
        className={`form-control ${errors[name] && "input-error"} ${className}`}
        {...register(name, {
          required: required && "This field is required",
        })}
        {...props}
      />
    </FormGroup>
  );
};

export const TextArea = ({
  register,
  label,
  name,
  errors,
  helpText,
  required = false,
  className = "",
  ...props
}) => {
  return (
    <FormGroup error={errors[name]} helpText={helpText}>
      <FormLabel htmlFor={name}>{label}</FormLabel>
      <textarea
        className={`form-control ${errors[name] && "input-error"} ${className}`}
        {...register(name, {
          required: required && "This field is required",
        })}
        {...props}
      />
    </FormGroup>
  );
};

export const Checkbox = ({
  register,
  label,
  name,
  errors,
  helpText,
  required = false,
  className = "",
  ...props
}) => {
  return (
    <FormGroup error={errors[name]} helpText={helpText} className="form-check">
      <input
        type="checkbox"
        className={`form-check-input ${errors[name] && "input-error"} ${className}`}
        {...register(name, {
          required: required && "This field is required",
        })}
        {...props}
      />
      <FormLabel htmlFor={name}>{label}</FormLabel>
    </FormGroup>
  );
};

export const SelectInput = ({
  register,
  label,
  name,
  errors,
  helpText,
  required = false,
  options,
  defaultValue,
  className = "",
  ...props
}) => {
  return (
    <FormGroup error={errors[name]} helpText={helpText}>
      <FormLabel htmlFor={name}>{label}</FormLabel>
      <select
        className={`form-control ${errors[name] && "input-error"} ${className}`}
        defaultValue={defaultValue}
        {...register(name, {
          required: required && "This field is required",
        })}
        {...props}
      >
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </FormGroup>
  );
};

export const NumberInput = ({
  register,
  label,
  name,
  errors,
  helpText,
  required = false,
  min,
  max,
  step = "1",
  className = "",
  ...props
}) => {
  return (
    <FormGroup error={errors[name]} helpText={helpText}>
      <FormLabel htmlFor={name}>{label}</FormLabel>
      <input
        type="number"
        className={`form-control ${errors[name] && "input-error"} ${className}`}
        min={min}
        max={max}
        step={step}
        {...register(name, {
          required: required && "This field is required",
          valueAsNumber: true,
        })}
        {...props}
      />
    </FormGroup>
  );
};