import { useNavigate, useParams } from "react-router-dom";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Input, TextArea } from "../../components/Inputs";
import { AutocompleteInput } from "../components/AutocompleteInput";
import { ProOverlay } from "../../components/Pro";

const api = {
  save: (id, data) =>
    fetch(`/api/seeding/list/${id ? `${id}/` : ''}`, {
      method: id ? "PUT" : "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        ...data,
        targets: data.stations.map(station => ({
          station: station.station.id,
          price_per_m3: station.price_per_m3,
          price_multiplier: station.price_multiplier
        }))
      })
    }).then(res => res.ok ? res.json() : res.json().then(err => Promise.reject(err))),

  delete: (id) =>
    fetch(`/api/seeding/list/${id}/`, { method: "DELETE" }),

  get: (id) =>
    fetch(`/api/seeding/list/${id}/?expand=targets.station,items.item`)
      .then(res => res.json())
};

const StationField = ({ index, control, register, remove, watch }) => {
  const cost_per_m3 = watch(`stations.${index}.price_per_m3`);
  const price_multiplier = watch(`stations.${index}.price_multiplier`);

  return (
    <div className="card card-block mb-3">
      <div className="card-body space-y-4">
        <div className="form-group">
          <label htmlFor={`stations.${index}.station`} className="form-label">
            Station/Structure
          </label>
          <Controller
            name={`stations.${index}.station`}
            control={control}
            render={({ field }) => (
              <AutocompleteInput
                name={field.name}
                type="station"
                placeholder="Start typing a station name..."
                onChange={field.onChange}
                inputProps={field}
                defaultValue={field.value}
              />
            )}
          />
          <p className="form-text text-white-50">
            The station or structure to apply this list to. Start typing to autocomplete.
          </p>
        </div>

        <div className="form-group">
          <label htmlFor={`stations.${index}.price_per_m3`} className="form-label">
            Cost Per m<sup>3</sup>
          </label>
          <input
            type="number"
            className="form-control"
            placeholder="140"
            {...register(`stations.${index}.price_per_m3`)}
          />
          <p className="form-text text-white-50">
            Additional cost per m³ for transport difficulties.
          </p>
        </div>

        <div className="form-group">
          <label htmlFor={`stations.${index}.price_multiplier`} className="form-label">
            Price Multiplier
          </label>
          <input
            type="number"
            className="form-control"
            placeholder="1"
            step={"0.01"}
            {...register(`stations.${index}.price_multiplier`)}
          />
          <p className="form-text text-white-50">
            Multiplier applied before adding the cost per m³.
          </p>
        </div>

        <div className={"mb-3"}>
          <h4 className={"fs-5"}>Formulas</h4>
          <p>
            The maximum fair price for an item being seeded in this location is calculated as: &nbsp;
            <code>
              max_fair_price = (item.price * {price_multiplier}) + ({cost_per_m3} * min(item.volume,
              item.packaged_volume))
            </code>.
            This means that if it cost 100 ISK to buy a unit of Tritanium in Jita, the fair price would be:
            <code>
             (100 * {price_multiplier}) + ({cost_per_m3} * 0.01) = {100 * price_multiplier + cost_per_m3 * 0.01} ISK
            </code>
          </p>
        </div>
      </div>
      <div className={"card-footer"}>
        <button
          className="btn btn-danger btn-sm w-100"
          type="button"
          onClick={() => remove(index)}
        >
          Remove
        </button>
      </div>
    </div>
  )
};

export function SeedingListForm() {
  const {id} = useParams();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const {
    register,
    handleSubmit,
    control,
    setError,
    reset,
    watch,
    formState: { errors }
  } = useForm({
    defaultValues: {
      stations: [],
      price_per_m3: 140,
      price_multiplier: 1
    },
    validate: {
      stations: (value) => value.length > 0 || "At least one station is required"
    }
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "stations"
  });

  const { mutate: saveList, isPending: isSaving } = useMutation({
    mutationFn: (data) => api.save(id, data),
    onError: (errors) => {
      Object.entries(errors).forEach(([key, messages]) => {
        setError(key, { type: "server", message: messages.join(", ") });
      });
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ["seeding"] });
      navigate(`/seeding/list/${data.id}`);
    }
  });

  const { mutate: deleteList } = useMutation({
    mutationFn: () => api.delete(id),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["seeding"] });
      navigate("/seeding");
    }
  });

  useQuery({
    queryKey: ["seeding", "list", id],
    enabled: !!id && /^-?[0-9]+$/.test(id),
    queryFn: () => api.get(id)
      .then(data => {
        reset({
          name: data.name,
          discord_webhook: data.discord_webhook,
          content: data.items
            .map(item => `${item.min_quantity} ${item.item.name}`)
            .join("\n"),
          stations: data.targets
            .filter(target => target.station)
            .map(station => ({
              station: {
                id: station.station.id,
                name: station.station.name
              },
              price_per_m3: station.price_per_m3,
              price_multiplier: station.price_multiplier
            }))
        });
        return data;
      })
  });

  return (
    <form onSubmit={handleSubmit(saveList)} className="dark space-y-4">
      <Input
        label="List Name"
        type="text"
        name="name"
        register={register}
        errors={errors}
        required
        autoComplete="off"
      />

      <TextArea
        label="List Content"
        name="content"
        register={register}
        errors={errors}
        placeholder="500 Multifrequency L\n500 Gamma L\n500 Xray L"
        rows={8}
        helpText="One item per line, format: 'quantity name'. Copy from game buy window or use shopping list tool."
        required
      />

      <ProOverlay text="Discord Notifications are only available to Pro users.">
        <Input
          label="Discord Webhook"
          type="text"
          name="discord_webhook"
          register={register}
          errors={errors}
          placeholder="https://discord.com/api/webhooks/..."
          autoComplete="off"
          helpText="Enter a Discord webhook URL to receive notifications for this list."
        />
      </ProOverlay>

      <div>
        <h3 className="fs-5">Stations/Structures</h3>
        <p>Add stations to track minimum quantities and fair prices at each location.</p>

        {fields.map((field, index) => (
          <StationField
            key={field.id}
            index={index}
            control={control}
            register={register}
            remove={remove}
            watch={watch}
          />
        ))}
        {errors.stations && (
          <div className="text-red-500 text-sm mt-2">
            {errors.stations.message}
          </div>
        )}

        <button
          className="btn btn-primary mb-3"
          type="button"
          onClick={() => append({ price_per_m3: 140, price_multiplier: 1.20 })}
        >
          Add Station/Structure
        </button>
      </div>

      <div className="form-group">
        <button
          className="btn btn-success"
          type="submit"
          disabled={isSaving}
        >
          {isSaving ? (
            <>
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              />
              &nbsp;Saving...
            </>
          ) : (
            "Save"
          )}
        </button>

        {id && (
          <button
            className="btn btn-danger mx-2"
            type="button"
            onClick={() => deleteList()}
          >
            Delete
          </button>
        )}
      </div>
    </form>
  );
}