import React from "react";
import {Content, Page, Sidebar, Top} from "../../components/Page";
import {NavLink, Outlet} from "react-router-dom";
import Jobs from "jsx:../../icons/jobs.svg";
import {ScopeBar} from "../../components/ScopeBar";

export function Industry () {
  return (
    <Page>
      <Sidebar>
        <Top>
          <div className={"list-group list-group-flush border-0"}>
            <NavLink
              end
              to={"/industry"}
              className={
                "list-group-item list-group-item-action border-0 d-flex align-items-center"
              }
            >
              <Jobs className={"icon me-2"} />
              <strong>Jobs</strong>
            </NavLink>
          </div>
        </Top>
      </Sidebar>
      <Content>
        <div className={"mb-3"}>
          <ScopeBar
            scopes={["esi-industry.read_character_jobs.v1", "esi-universe.read_structures.v1"]}
            optional={["esi-characters.read_corporation_roles.v1", "esi-industry.read_corporation_jobs.v1"]}
          />
        </div>
        <Outlet />
      </Content>
    </Page>
  )
}