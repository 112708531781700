import { useState, useCallback } from "react";
import { useCombobox } from "downshift";
import {
  useFloating,
  autoUpdate,
  autoPlacement,
  size,
} from "@floating-ui/react-dom";
import { FloatingPortal } from "@floating-ui/react";
import { IconCell, security } from "../../components/Cells";
import Structure from "jsx:../../icons/structure.svg";
import System from "jsx:../../icons/system.svg";
import Universe from "jsx:../../icons/universe.svg";
import Fuse from "fuse.js";
import {useStorage} from "../../hooks/useStorage";

const EMPTY = [];

// Existing renderItem function remains the same
export function renderItem(type, item) {
  if (type === "station") {
    return (
      <>
        {security(item.system.security)}&nbsp;-&nbsp;
        <strong>{item.name}</strong>
      </>
    );
  } else if (type === "type") {
    return (
      <>
        <IconCell
          id={item.id}
          name={item.name}
          iconSize={32}
          className="me-1"
          style={{
            height: "16px",
          }}
        />
        <strong>{item.name}</strong>
      </>
    );
  } else if (type === "container") {
    return (
      <>
        <strong>{item.name}</strong>
        {item.location && <div className="fs-6">{item.location.name}</div>}
        <div className="fs-6"></div>
      </>
    );
  } else if (type === "location" || type === "regions") {
    const Icon = {
      station: Structure,
      system: System,
      region: Universe,
    }[item.type];

    return (
      <>
        <Icon className="icon sm me-2" />
        <strong>{item.name}</strong>
      </>
    );
  } else if (
    type === "character" ||
    type === "entity" ||
    type === "lp_corporation"
  ) {
    return (
      <>
        <IconCell
          id={item.id}
          name={item.name}
          iconSize={32}
          className="me-1"
          style={{
            height: "16px",
          }}
        />
        <strong>{item.name}</strong>
      </>
    );
  } else {
    return <>{item.name}</>;
  }
}

export function AutocompleteInput({
  label,
  type,
  onChange,
  value,
  inputProps,
  helpText,
  defaultValue,
}) {
  const [items, setItems] = useState(EMPTY);
  const [aborter, setAborter] = useState(null);
  const [isFocused, setIsFocused] = useState(false);
  
  // Use the storage hook for favorites
  const [favorites, setFavorites] = useStorage(`favorites-${type}`, []);

  const toggleFavorite = useCallback((item) => {
    setFavorites(prev => {
      const exists = prev.some(fav => fav.id === item.id);
      if (exists) {
        return prev.filter(fav => fav.id !== item.id);
      } else {
        return [...prev, item];
      }
    });
  }, [setFavorites]);

  const {
    getLabelProps,
    getInputProps,
    getMenuProps,
    getItemProps,
    isOpen,
    highlightedIndex,
    selectedItem,
  } = useCombobox({
    onInputValueChange: ({ inputValue }) => {
      if (inputValue?.length > 0) {
        if (aborter) {
          aborter.abort();
        }

        const abb = new AbortController();
        setAborter(abb);

        fetch(`/api/autocomplete/?type=${type}&query=${inputValue}`, {
          signal: abb.signal,
        })
          .then((res) => res.json())
          .then((data) => {
            const fuse = new Fuse(data.results, {
              keys: ["name"],
              threshold: 0.5,
            });
            setItems(fuse.search(inputValue).map((result) => result.item));
          })
          .catch((e) => {
            if (e.name !== "AbortError") {
              throw e;
            }
          });
      } else {
        setItems(EMPTY);
        if (inputValue?.length === 0) {
          onChange(null);
        }
      }
    },
    onSelectedItemChange: ({ selectedItem }) => {
      onChange(selectedItem);
    },
    items: items.length > 0 ? items : (isFocused && !items.length ? favorites : EMPTY),
    itemToString: (item) => item?.name,
    selectedItem: value,
    defaultSelectedItem: defaultValue,
  });

  const { refs, floatingStyles } = useFloating({
    whileElementsMounted: autoUpdate,
    open: isOpen,
    middleware: [
      size({
        apply({ rects, elements }) {
          Object.assign(elements.floating.style, {
            width: `${rects.reference.width}px`,
          });
        },
      }),
      autoPlacement({
        allowedPlacements: [
          "bottom-start",
          "bottom-end",
          "top-start",
          "top-end",
        ],
      }),
    ],
  });

  const displayItems = items.length > 0 ? items : (isFocused && !items.length ? favorites : EMPTY);
  const showMenu = isOpen && (displayItems.length > 0);

  return (
    <>
      <div className="form-group" ref={refs.setReference}>
        {label && (
          <label className="form-label" {...getLabelProps()}>
            {label}
          </label>
        )}
        <input
          className="form-control"
          {...inputProps}
          {...getInputProps({
            onFocus: () => setIsFocused(true),
            onBlur: () => {
              // Small delay to allow click events on the menu to fire
              setTimeout(() => setIsFocused(false), 200);
            }
          })}
        />
        <FloatingPortal>
          <div
            className={`popup ${showMenu ? "" : "d-none"}`}
            ref={refs.setFloating}
            style={floatingStyles}
          >
            <ul
              className="list-group border-0 overflow-y-auto"
              style={{
                maxHeight: "200px",
              }}
              {...getMenuProps()}
            >
              {showMenu &&
                displayItems.map((item, index) => {
                  const isFavorite = favorites.some(fav => fav.id === item.id);
                  return (
                    <li
                      key={item.id}
                      className={[
                        "list-group-item",
                        "list-group-item-action",
                        "d-flex",
                        "justify-content-between",
                        "align-items-center",
                        highlightedIndex === index ? "active" : "",
                      ].join(" ")}
                      style={{
                        color: selectedItem === item ? "white" : "",
                      }}
                      {...getItemProps({ item, index })}
                    >
                      <div className="d-flex align-items-center flex-grow-1">
                        {renderItem(type, item)}
                      </div>
                      <button
                        className="btn btn-link text-decoration-none p-0 ms-2"
                        onClick={(e) => {
                          e.stopPropagation();
                          toggleFavorite(item);
                        }}
                        style={{
                          fontSize: '1.2rem',
                          lineHeight: 1,
                          color: isFavorite ? '#ffd700' : '#6c757d'
                        }}
                      >
                        {isFavorite ? '★' : '☆'}
                      </button>
                    </li>
                  );
                })}
            </ul>
          </div>
        </FloatingPortal>
        {helpText && (
          <small className="form-text text-white-50">{helpText}</small>
        )}
      </div>
    </>
  );
}