import React, { useMemo } from "react";
import { Header } from "../../components/Page";
import { ScopeBar } from "../../components/ScopeBar";
import { Table } from "../../components/Table";
import { useQuery } from "@tanstack/react-query";
import { Loading } from "../../components/Loading";
import { Link } from "react-router-dom";
import { characterCell } from "../../components/Cells";

/**
 * Calculate total loyalty points by corporation
 * @param {Array} data - Array of loyalty point entries
 * @returns {Array} Array of corporation totals
 */
const calculateCorporationTotals = (data) => {
  const totals = data.reduce((acc, entry) => {
    const { corporation, points } = entry;
    if (!acc[corporation.id]) {
      acc[corporation.id] = { ...corporation, totalPoints: 0 };
    }
    acc[corporation.id].totalPoints += points;
    return acc;
  }, {});

  return Object.values(totals).sort((a, b) => b.totalPoints - a.totalPoints);
};

/**
 * Group loyalty points by character
 * @param {Array} data - Array of loyalty point entries
 * @returns {Object} Object with character IDs as keys and arrays of their loyalty point entries as values
 */
const groupByCharacter = (data) => {
  return data.reduce((acc, entry) => {
    if (!acc[entry.character.id]) {
      acc[entry.character.id] = [];
    }
    acc[entry.character.id].push(entry);
    return acc;
  }, {});
};

export function LP() {
  const { data, isLoading } = useQuery({
    queryKey: ["loyalty", "points"],
    queryFn: () => {
      const url = new URL("/api/loyalty/points/", window.location.origin);
      url.searchParams.set("expand", ["corporation", "character"].join(","));

      return fetch(url).then((res) => res.json());
    },
  });

  const corporationTotals = useMemo(() => {
    return data ? calculateCorporationTotals(data) : [];
  }, [data]);

  const characterGroups = useMemo(() => {
    return data ? groupByCharacter(data) : {};
  }, [data]);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      <Header
        title={"Loyalty Points"}
        description={
          <>
            Loyalty Points are earned by completing missions for NPC
            corporations. They can be exchanged for items in the corporation's
            loyalty point store.
          </>
        }
      />
      <div className={"mb-4"}>
        <ScopeBar scopes={["esi-characters.read_loyalty.v1"]} />
      </div>
      
      <h4 className="mb-3">Totals</h4>
      <Table
        columns={[
          {
            header: "Corporation",
            accessorKey: "name",
            cell: (cell) => {
              const { id, name } = cell.row.original;
              return (
                <>
                  <img
                    src={`https://images.evetech.net/corporations/${id}/logo`}
                    alt={name}
                    className={"icon me-2"}
                  />
                  {name}
                </>
              );
            },
          },
          {
            header: "Total Loyalty Points",
            accessorKey: "totalPoints",
            meta: {
              align: "end",
            },
          },
        ]}
        rows={corporationTotals}
      />

      <h4 className="mt-5 mb-3">By Character</h4>
      {Object.entries(characterGroups).map(([characterId, characterData]) => (
        <div key={characterId} className="mb-5">
          <div className={"mb-3"}>
            {characterCell(characterId, characterData[0].character.name)}
          </div>
          <Table
            columns={[
              {
                header: "Corporation",
                accessorKey: "corporation",
                cell: (cell) => {
                  const value = cell.getValue();
                  return (
                    <>
                      <img
                        src={`https://images.evetech.net/corporations/${value.id}/logo`}
                        alt={value.name}
                        className={"icon me-2"}
                      />
                      {value.name}
                    </>
                  );
                },
              },
              {
                header: "Loyalty Points",
                accessorKey: "points",
                meta: {
                  align: "end",
                },
              },
            ]}
            rows={characterData}
          />
        </div>
      ))}
    </>
  );
}