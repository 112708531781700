import {Content, Header, Page} from "../../components/Page";
import {ScopeBar} from "../../components/ScopeBar";
import {useQuery} from "@tanstack/react-query";
import {CharacterAvatar, IconCell} from "../../components/Cells";
import {applyFiltersToURL, FilterBuilder, filtersToQuery} from "../../components/FilterBuilder";
import {useStorage} from "../../hooks/useStorage";
import {AutocompleteInput} from "../components/AutocompleteInput";
import {Table} from "../../components/Table";
import {Link} from "react-router-dom";

export function Fittings() {
  const [filters, setFilters] = useStorage("fittings.filters", []);
  const { data: fittings, isLoading } = useQuery({
    queryKey: ["fittings", filtersToQuery(filters)],
    queryFn: async () => {
      const params = new URLSearchParams();
      params.append("expand", "ship_item,items,items.item,character");
      if (filters) {
        applyFiltersToURL(params, filters);
      }
      const response = await fetch(`/api/fittings/?${params.toString()}`);
      return response.json();
    },
  });

  return (
    <Page>
      <Content>
        <Header title={"Fittings"} description={
          <>
            Shows all of the fittings that have been saved by your characters
            in-game.
          </>
        }/>
        <ScopeBar scopes={["esi-fittings.read_fittings.v1", "esi-fittings.write_fittings.v1"]}/>
        <div className={"mt-4"}>
          <FilterBuilder
            filters={filters}
            onChange={setFilters}
            allowedFilters={{
              item: {
                label: "Item",
                operators: ["==", "!="],
                component: AutocompleteInput,
                props: {
                  type: "type",
                },
              },
              character: {
                label: "Character",
                operators: ["==", "!="],
                component: AutocompleteInput,
                props: {
                  type: "character",
                },
              },
            }}
          />
          <Table
            columns={[
              {
                header: "",
                id: "character",
                accessorKey: "character.id",
                cell: (cell) => {
                  const original = cell.row.original;
                  return <CharacterAvatar id={original.character.id} name={original.character.name} />;
                }
              },
              {
                header: "Ship",
                accessorKey: "ship_item",
                meta: {
                  align: "center",
                },
                cell: (cell) => {
                  return <IconCell id={cell.row.original.ship_item.id} name={cell.row.original.ship_item.name} />;
                },
              },
              {
                header: "Name",
                accessorKey: "name",
                meta: {
                  cellClasses: "w-100",
                },
                cell: (cell) => {
                  const original = cell.row.original;
                  return <Link to={`/fittings/${original.id}`}>{original.name}</Link>;
                }
              },
              {
                header: "Items",
                accessorKey: "items",
                cell: (cell) => {
                  return cell.getValue().length;
                },
              },
            ]}
            rows={fittings}
            isLoading={isLoading}
          />
        </div>
      </Content>
    </Page>
  )
}