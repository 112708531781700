import { Content, Header, Page } from "../../components/Page";
import { AutocompleteInput } from "../components/AutocompleteInput";
import { compactNumber } from "../../formatting";
import { DataTable, useDataTable } from '../../components/DataTable';
import { CharacterAvatar, CompactStationLocation, IconCell, TypeLink } from "../../components/Cells";
import { ScopeBar } from "../../components/ScopeBar";
import { Link }from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { Loading } from "../../components/Loading";
import {useState} from "react";
import {Modal} from "react-bootstrap";

export function AssetsTable({ defaultFilters = [], className = '', hideFilters = false, parent_id = null}) {
  const [child, setChild] = useState(null);
  const [state, actions] = useDataTable({
    endpoint: '/api/assets/',
    expand: ['item', 'item.fair_price', 'location', 'character'],
    defaultSorting: [
      {
        id: "sell",
        desc: true,
      }
    ],
    defaultFilters,
  });

  return (
    <>
      <DataTable
        state={state}
        actions={actions}
        className={className}
        columns={[
          {
            header: "",
            id: "character",
            accessorFn: (row) => row.character?.name || row.character,
            cell: (cell) => {
              const original = cell.row.original;
              return <CharacterAvatar id={original.character.id} name={original.character.name} />;
            }
          },
          {
            id: "id",
            header: "",
            accessorKey: "item.id",
            cell: (cell) => {
              const { item } = cell.row.original;
              return <IconCell id={item.id} name={item.name} iconSize={32} />;
            }
          },
          {
            header: "Item",
            accessorKey: "item.name",
            meta: {
              cellClasses: "w-100",
            },
            cell: (cell) => {
              const { item, is_singleton, parent, name, asset_id, is_blueprint_copy } = cell.row.original;
              const isContainer = is_singleton && item.capacity;

              return (
                <div className="row g-4">
                  <div className={"col"}>
                    <TypeLink id={item.id} name={name || item.name} />
                  </div>
                  <div className={"col col-auto"}>
                    {is_blueprint_copy && (
                      <span className={"badge text-bg-info ms-1"}>BPC</span>
                    )}
                    {isContainer ? (
                      <button
                        className={"btn btn-sm btn-outline-secondary"}
                        onClick={() => setChild(asset_id)}
                      >
                        Contents
                      </button>
                    ) : null}
                    {parent && parent !== parent_id ? (
                      <button
                        className={"btn btn-sm btn-outline-secondary"}
                        onClick={() => setChild(parent)}
                      >
                        Parent
                      </button>
                    ) : null}
                  </div>
                </div>
              );
            }
          },
          {
            id: "quantity",
            header: "Qty",
            accessorKey: "quantity",
            meta: {
              align: "end",
            },
            cell: (cell) => compactNumber(cell.getValue())
          },
          {
            id: "sell",
            header: (
              <abbr title="Estimated from the Jita sell price, and does not include contents.">
                Price
              </abbr>
            ),
            accessorKey: "sell",
            meta: {
              align: "end",
            },
            cell: (cell) => compactNumber(cell.getValue())
          },
          {
            header: "Location",
            accessorKey: "location",
            meta: {
              align: "end",
              nowrap: true
            },
            cell: (cell) => {
              const location = cell.getValue();
              const original = cell.row.original;

              if (location) {
                return <CompactStationLocation location={location} />;
              } else {
                return <em>{original.location_flag.name}</em>;
              }
            },
          },
        ]}
        filtering={{
          enabled: !hideFilters,
          allowedFilters: {
            price: {
              label: "Price",
              operators: [">=", "<="],
            },
            type: {
              label: "Item",
              operators: ["==", "!="],
              component: AutocompleteInput,
              props: {
                type: "type",
              },
            },
            location: {
              label: "Location",
              operators: ["==", "!="],
              component: AutocompleteInput,
              props: {
                type: "location",
              },
            },
            group: {
              label: "Group",
              operators: ["&&", "!&"],
              component: AutocompleteInput,
              props: {
                type: "group",
              },
            },
            character: {
              label: "Character",
              operators: ["==", "!="],
              component: AutocompleteInput,
              props: {
                type: "character",
              },
            },
          }
        }}
      />
      {child && (
        <Modal show={true} onHide={() => setChild(null)} fullscreen={true}>
          <Modal.Body>
            <Asset id={child} />
          </Modal.Body>
          <Modal.Footer>
            <button className={"btn btn-outline-info w-100"} onClick={() => setChild(null)}>Close</button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
}

export function Assets() {
  return (
    <Page>
      <Content>
        <Header
          title="Assets"
          description={
            <>
              The assets page provides an overview of all inventory assets
              across all of your
              <span className="text-info"> characters</span> and
              <span className="text-info"> corporations.</span>
            </>
          }
        />
        <div className="mb-3">
          <ScopeBar
            scopes={["esi-assets.read_assets.v1"]}
            optional={["esi-assets.read_corporation_assets.v1"]}
          />
        </div>
        <AssetsTable />
      </Content>
    </Page>
  );
}

export function Asset({ id }) {
  const { data, isLoading } = useQuery({
    queryKey: ["asset", id],
    queryFn: () => {
      const params = new URLSearchParams();
      params.append('expand', ['item', "location"].join(','));
      return fetch(`/api/assets/${id}/?${params.toString()}`).then((res) => res.json())
    },
  });

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      <div className={"d-flex mb-3"}>
        <IconCell id={data.item.id} name={data.item.name} iconSize={64} className={"me-2"}/>
        <div>
          <h1 className={"fs-4"}><TypeLink id={data.item.id} name={data.item.name}/></h1>
          {data.location && <CompactStationLocation location={data.location}/>}
        </div>
      </div>
      <AssetsTable defaultFilters={[{type: "parent", operator: "==", value: id}]} parent_id={parseInt(id)} />
    </>
  );
}
