import {Block, BlockBody} from "./Block";

export function Sidebar({ children }) {
  return (
    <div className={"col-12 col-md-3 col-xxl-2 vh-100 sidebar sidebar-page"}>
      <div className={"d-flex flex-column vh-100 py-3 sidebar-inner"}>
        {children}
      </div>
    </div>
  );
}

export function Top({ children, className = "" }) {
  return <div className={`flex-grow-1 overflow-y-auto ${className}`}>{children}</div>;
}

export function Bottom({ children, className = "" }) {
  return <div className={className}>{children}</div>;
}

export function Content({ children }) {
  return (
    <div className={"col vh-100 overflow-y-auto content p-4"}>{children}</div>
  );
}

export function CenteredContent({ children }) {
  return (
    <div className={"d-flex justify-content-center align-items-center h-100"}>
      <div className={"text-center"}>{children}</div>
    </div>
  );
}

export function Header({ title, description = null }) {
  return (
    <div className={"row mb-3"}>
      <div className={"col-12"}>
        <h1 className={"fs-3"}>{title}</h1>
        {description && <div>{description}</div>}
      </div>
    </div>
  );
}

export function Page({
  children,
  prefix = "cl",
}) {
  return (
    <div className={`container-fluid vh-100 ${prefix}-container`}>
      <div className={`row vh-100 ${prefix}-content`}>{children}</div>
    </div>
  );
}

export function Stat({ title, value }) {
  return (
    <div className={"col-lg-3 col-md-6 col-12"}>
      <Block>
        <BlockBody>
          <h5 className={"card-title"}>{title}</h5>
          <div className={"card-text"}>{value}</div>
        </BlockBody>
      </Block>
    </div>
  );
}

export function Stats({ children }) {
  return <div className={"row mb-3 text-center gy-1"}>{children}</div>;
}
