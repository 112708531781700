import {useQuery} from "@tanstack/react-query";
import {Loading} from "../../components/Loading";
import {Table} from "../../components/Table";
import {CharacterAvatar, CompactStationLocation, TypeCell} from "../../components/Cells";

export function IndustryJobs () {
  const { data, isLoading } = useQuery({
    queryKey: ["industry", "jobs"],
    queryFn: () => {
      const params = new URLSearchParams();
      params.set("expand", [
        "blueprint_type",
        "blueprint_type.item",
        "station",
        "station.system",
        "installer",
      ].join(","));
      return fetch(`/api/industry/jobs/?${params.toString()}`).then((res) =>
        res.json(),
      );
    }
  });

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      <div className={"alert alert-info"}>
        The industry tab is currently under development and here as an early preview.
      </div>
      <h1 className={"fs-4 mb-3"}>Industry Jobs</h1>
      <Table
        columns={[
          {
            id: "installer",
            header: "",
            accessorFn: (row) => row.installer?.name || row.installer,
            cell: (cell) => {
              const original = cell.row.original;
              return <CharacterAvatar id={original.installer.id} name={original.installer.name} />;
            }
          },
          {
            header: "Status",
            accessorKey: "status",
            meta: {
              align: "center"
            }
          },
          {
            header: "Runs",
            accessorKey: "runs",
            meta: {
              align: "center"
            },
            cell: (cell) => {
              return `x ${cell.getValue().toLocaleString()}`;
            }
          },
          {
            header: "Blueprint",
            accessorKey: "blueprint_type.item.name",
            meta: {
              cellClasses: "w-100"
            },
            cell: (cell) => {
              const original = cell.row.original;
              return <TypeCell name={original.blueprint_type.item.name} id={original.blueprint_type.item.id} />;
            }
          },
          {
            header: "Location",
            accessorFn: (row) => row.station?.name || row.station,
            meta: {
              nowrap: true
            },
            cell: (cell) => {
              const original = cell.row.original;
              return <CompactStationLocation location={original.station} />;
            }
          }
        ]}
        rows={data}
        isLoading={isLoading}
      />
    </>
  )
}